import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import { Row, Col, Card, Tooltip, OverlayTrigger } from 'react-bootstrap';
// External libraries
import moment from 'moment';

// components
import ShippingModal from './ShippingModal';

const { FormatMoney } = require('format-money-js');

const fmDecimal = new FormatMoney({
  decimals: 2,
});

const ShippingCard = ({ data, index }) => {
  const [isModalAdOpen, setIsModalAdOpen] = useState(false);
  const [isModalOwOpen, setIsModalOwOpen] = useState(false);
  return (
    <>
      {/* Modal Add Charges Start */}
      {isModalAdOpen && <ShippingModal data={data} isModalOpen={isModalAdOpen} setIsModalOpen={setIsModalAdOpen} title="Cargos Adicionales" type="ad" />}
      {isModalOwOpen && <ShippingModal data={data} isModalOpen={isModalOwOpen} setIsModalOpen={setIsModalOwOpen} title="Sobrepeso" type="ow" />}
      {/* Modal Add Charges End */}
      <Card className="mb-2 py-4 py-lg-0 ps-5 pe-4 h-100 text-alternate">
        <Row className="g-0 h-100 sh-lg-9 position-relative overflow-y-hidden">
          {/* <div className="lh-1 text-muted">{`${counter}.`}</div> */}
          <Col xs="12" lg="3" className="d-flex flex-column mb-lg-0 mb-3 pe-3 d-flex  h-lg-100 justify-content-center order-3 order-lg-1">
            <div className="text-muted text-small d-lg-none">Razón Social</div>
            {/* <OverlayTrigger delay={{ show: 800, hide: 0 }} placement="top" overlay={<Tooltip id="tooltip-top">Ver perfil</Tooltip>}></OverlayTrigger> */}
            <NavLink className="anchor-effect-primary" to={`/users-all/detail/${data?.crm_user}`}>
              {`${index + 1}. ${data.razon_social.toUpperCase()}`}
            </NavLink>
          </Col>

          <Col xs="6" lg="1" className=" d-flex flex-column pe-1 pe-lg-2 mb-2 mb-lg-0 justify-content-center order-last order-lg-2">
            <div className="text-muted text-small d-lg-none">Fecha / Hora</div>
            {/* creation_date:"2024-10-04T11:40:59.362067Z" */}

            <div className="lh-1 text-alternate">{`${moment(data.creation_date).format('DD-MM-YY')} ${data?.creation_date.slice(11, 19)}`}</div>
          </Col>

          <Col xs="12" lg="3" className="d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center align-items-lg-center order-4 order-lg-3">
            <div className="text-muted text-small d-lg-none">ID Envio</div>

            <OverlayTrigger delay={{ show: 800, hide: 0 }} placement="top" overlay={<Tooltip id="tooltip-top">Ver detalle de envío</Tooltip>}>
              <NavLink className="anchor-effect-primary" to={`/shipping-all/detail/${data?.shipping_label_id}/${data?.razon_social}/${data?.crm_user_id}`}>
                {data?.shipping_label_id}
              </NavLink>
            </OverlayTrigger>
          </Col>

          <Col lg="3" className=" d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center order-5 order-lg-4">
            <div className="text-muted text-small d-lg-none">Servicio</div>

            <div>{data.shipping_code?.replace(/_+/g, ' ')}</div>
          </Col>
          <Col xs="6" lg="1" className="d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center align-items-end order-last order-lg-5">
            <div className="text-muted text-small d-lg-none">Costo</div>

            <div className="lh-1 text-primary">
              <span className="text-small text-muted">$</span>
              {fmDecimal.from(data.shipping_rates?.total)}
            </div>
          </Col>
          <Col xs="12" lg="1" className="ps-4 d-flex mb-2 mb-lg-0 align-items-center justify-content-end order-1 order-lg-last">
            <div onClick={() => setIsModalAdOpen(true)}>
              <CsLineIcons icon="wallet" className="anchor-effect-primary me-2 cursor-pointer" />
            </div>
            <div onClick={() => setIsModalOwOpen(true)}>
              <CsLineIcons icon="warning-hexagon" className="anchor-effect-primary cursor-pointer" />
            </div>
          </Col>
        </Row>
      </Card>
    </>
  );
};
export default ShippingCard;
