import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';

// utils
import { cleanShippingId } from 'utils/textFormat';

// components
import AutoFill from '../../../../components/form-elements/AutoFill';
import InputEmail from '../../../../components/form-elements/InputEmail';
import InputTextFetchData2 from '../../../../components/form-elements/InputTextFetchData2';
import { ToastErrorApi, ToastErrorCath } from '../../../../components/alerts/toast';
import { getShippingDetails } from '../../../../api/Api-user';

const ReportInfo = ({ values, setValues, errors, setErrors }) => {
  const [isGettingData, setIsGettingData] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState('');
  const [deleteCount, setDeleteCount] = useState(0);
  // - Global State from Store.js form Values
  const { currentUser } = useSelector((state) => state.auth);
  // Function to reset form values
  const resetFormValues = () => {
    setValues(() => {
      // Create a new object with properties reset to empty strings
      const resetValues = Object.keys(values).reduce((acc, key) => {
        // Maintain shippingId value
        acc[key] = key === 'shippingId' ? values[values.shippingId] : '';
        return acc;
      }, {});
      // Return the object with the properties reset
      return resetValues;
    });
  };
  // Function to reset error values
  const resetFormErrors = () => {
    setErrors(() => {
      // Crear un nuevo objeto con las propiedades reseteadas a cadenas vacías
      const resetErrors = Object.keys(errors).reduce((acc, key) => {
        // shippingId as null instead of ''
        // acc[key] = key === 'shippingId' ? null : '';
        acc[key] = '';

        return acc;
      }, {});
      // Retornar el objeto con las propiedades reseteadas
      return resetErrors;
    });
  };
  // Function to handle errors
  const handleError = (errorMessage) => {
    setIsError(true);
    setError(errorMessage);
  };
  // Function to reset errors
  const resetErrors = () => {
    setIsError(false);
    setError('');
  };
  const getData = async (e) => {
    const key = e.nativeEvent.inputType;
    const inputValue = e.target.value;
    const cleanedInput = cleanShippingId(inputValue);
    // reset validation errors
    resetFormErrors();
    // If the user is deleting
    if (key === 'deleteContentBackward' || key === 'deleteContentForward') {
      if (deleteCount >= 5) {
        // remove all functionality if the user deletes more than 2
        //  characters to avoid massive 406 errors
        resetErrors();
        return;
      }
    } else {
      // Reset the counter if the user is typing
      setDeleteCount(0);
    }
    // Only searches for data if the value is more than 10 characters
    if (inputValue.length > 5) {
      setIsGettingData(true);
      try {
        const response = await getShippingDetails(cleanedInput);
        // console.log('datos de la guia:', response);
        if (response.shipping_code !== undefined) {
          setIsError(false);
          setValues((prevValues) => ({
            ...prevValues,
            // shippingId: inputValue,
            serviceName: response.shipping_company,
            razonSocial: response.razon_social,
            isInsurance: response.parcel.insurance,
            street: response.address_to.street,
            streetNumber: response.address_to.street_number,
            neighborhood: response.address_to.neighborhood,
            cp: response.address_to.zip_code,
            city: response.address_to.city,
            state: response.address_to.state,
            references: response.address_to.reference,
            phoneTo: response.address_to.phone,
            email: currentUser.email || '',
            label: response.label,
            // address from
            // referencesFrom: response.address_to.reference,
            // phoneFrom: response.address_to.phone,
          }));
        } else if (response.data.error) {
          handleError(response.data.error.shippings[0]);
          resetFormErrors();
          resetFormValues();
        } else {
          resetFormValues();
        }
      } catch {
        ToastErrorCath();
      } finally {
        setIsGettingData(false);
      }
    } else {
      resetErrors();
    }
  };
  useEffect(() => {
    if (values.shippingId?.length === 0) {
      resetFormValues();
    }
  }, [values.shippingId]);
  return (
    <Row className="g-3 my-5">
      <Col md="6" className="position-relative">
        <InputTextFetchData2
          label="ID Envío"
          icon="shipping"
          maxLength=""
          placeholder="Pega el Id de tu envío"
          value={values.shippingId}
          isRequired="true"
          isError={isError}
          error={error}
          getShippingData={getData}
          isLoading={isGettingData}
          formObj={setValues}
          validation={/^[A-Z0-9 ]+$/i}
          fieldName="shippingId"
        />
        {errors.shippingId && <div className={`invalid-tooltip ${errors.shippingId ? 'd-block' : 'd-none'}`}>{errors.shippingId}</div>}
      </Col>
      <Col md="6">
        <AutoFill label="Paquetería" value={values.serviceName} />
      </Col>
      <Col md="6" className="position-relative">
        <InputEmail
          label="Email"
          placeholder="Aquí recibirás actualizaciones de tu reporte"
          value={values.email}
          isRequired="true"
          helpText="Utiliza un correo donde puedas recibir nuestros mensajes"
          setObj={setValues}
          setErrObj={setErrors}
        />
        {errors.email && <div className={`invalid-tooltip ${errors.email ? 'd-block' : 'd-none'}`}>{errors.email}</div>}
      </Col>
      {/* <Col md="6">
        <AutoFill label="Razon Social" value={values.razonSocial} />
      </Col> */}
      {/* <Col md="6">
        <label className="me-2 form-label">Envío con póliza de seguro</label>
        <input type="checkbox" className="form-check-input me-2" readOnly checked={values.isInsurance} />
      </Col> */}
    </Row>
  );
};

export default ReportInfo;
