import { apiV1AxiosInstance, apiV1AxiosInstanceLogin } from '../../api/http';

// Base URL://api.autopaquete.com.mx/v1
const loginAdmin = (username, password) =>
  apiV1AxiosInstanceLogin(`/admin-login`, {
    method: 'POST',
    data: {
      username,
      password,
    },
  })
    .then((response) => {
      console.log('login admi response:', response);
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
// ****************  GETS  ******************************
// admin-shippings
const getAdminShippings = (num, limit, crmId, labelId, startDate, endDate) =>
  apiV1AxiosInstance(
    `/admin-shippings/list/?page=${num}&page_size=${limit}&crm_user_id=${crmId}&shipping_id=${labelId}&date=${startDate}&end_date=${endDate}`,
    {
      method: 'GET',
      timeout: 45000,
    }
  )
    .then((response) => {
      console.log('-------- getAdminShippings response  ---------->', response);
      return response.data;
      // return response;
    })
    .catch((error) => {
      console.log('-------- getAdminShippings catch err.response ---------->', error.response);
      console.log('-------- getAdminShippings catch err.config ---------->', error.config);
      console.log('-------- getAdminShippings catch err.code ---------->', error.code);
      console.log('-------- getAdminShippings catch err.request ---------->', error.request);
      console.log('-------- getAdminShippings catch err.isAxiosError ---------->', error.isAxiosError);
      console.log('-------- getAdminShippings catch err.toJSON ---------->', error.toJSON);
      // error = ['config', 'code', 'request', 'response', 'isAxiosError', 'toJSON']
      return error.response;
    });

const getAdminDetailShipping = (labelId) =>
  apiV1AxiosInstance(`/admin-shippings/detail/${labelId}`, {
    method: 'GET',
  })
    .then((response) => {
      console.log('-------- getAdminDetailShipping ---------->', response);
      return response.data;
    })
    .catch((error) => {
      console.log('-------- getAdminDetailShipping catch err ---------->', error.response);
      return error.response;
    });
// https://api.autopaquete.com.mx/v1/admin-additional-charges?charge_type=REDUCES_BALANCE
// https://api.autopaquete.com.mx/v1/admin-additional-charges?charge_type=INCREASES_BALANCE
const getChargeConcepts = (type) =>
  apiV1AxiosInstance(`/admin-additional-charges?charge_type=${type}`, {
    method: 'GET',
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- getChargeConcepts catch err ---------->', error.response);
      return error.response;
    });
const getServicesName = (num, limit) =>
  apiV1AxiosInstance(`/admin-shippings?page=${num}&page_size=${limit}`, {
    method: 'GET',
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- getServicesName catch err ---------->', error.response);
      return error.response;
    });
// identifier can be user name or user id
const getUserAccounts = (num, limit, identifier = '') =>
  apiV1AxiosInstance(`/admin-customers?page=${num}&page_size=${limit}&term=${identifier}`, {
    method: 'GET',
    timeout: 35000,
  })
    .then((response) => {
      // console.log('-------- getUserAccounts response ---------->', response.data);

      return response.data;
    })
    .catch((error) => {
      // console.log('-------- getUserAccounts catch err ---------->', error.response);
      return error.response;
    });
// user detail
const getUser = (crmId) =>
  apiV1AxiosInstance(`/admin-customers?page=1&page_size=1&term=${crmId}`, {
    method: 'GET',
    // timeout: 35000,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- getUser catch err ---------->', error.response);
      return error.response;
    });
const getBillingDocs = (docType, crmId, page, limit) =>
  apiV1AxiosInstance(`/admin-${docType}?page=${page}&page_size=${limit}&crm_user=${crmId}`, {
    method: 'GET',
    timeout: 35000,
  })
    .then((response) => {
      console.log('-------- getBillingDocs response  ---------->', response.data);

      return response.data;
    })
    .catch((error) => {
      console.log('-------- getBillingDocs catch err ---------->', error.response.data);
      return error;
    });
const getStatements = (crmId, type, page, limit) =>
  apiV1AxiosInstance(`/admin-statements?page=${page}&page_size=${limit}&crm_user=${crmId}&statement_code=${type}`, {
    method: 'GET',
    timeout: 35000,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- getStatements catch err ---------->', error.response.data);
      return error;
    });
const getOverweights = (crmId, page, limit) =>
  apiV1AxiosInstance(`/admin-overweights?page=${page}&page_size=${limit}&crm_user=${crmId}`, {
    method: 'GET',
    timeout: 35000,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- getOverweights catch err ---------->', error.response.data);
      return error;
    });

const getComments = (crmId, num, limit) =>
  apiV1AxiosInstance(`/admin-comments/${crmId}/comments?page=${num}&page_size=${limit}`, {
    method: 'GET',
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- getComments catch err ---------->', error.response);
      return error.response;
    });
const getOverweightOrders = (num, limit) =>
  apiV1AxiosInstance(`/admin-overweights-orders?page=${num}&page_size=${limit}`, {
    method: 'GET',
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- getOverweightOrders catch err ---------->', error.response);
      return error.response;
    });
const getOverweightOrderById = (orderId) =>
  apiV1AxiosInstance(`/admin-overweights-orders/${orderId}`, {
    method: 'GET',
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- getOverweightOrderById catch err ---------->', error.response);
      return error.response;
    });
const getOrderExcel = (type) =>
  apiV1AxiosInstance('/download-format', {
    method: 'POST',
    data: {
      code_concept: type,
    },
  })
    .then((response) => {
      //  console.log('-------- getOrderExcel res ---------->', response);
      return response.data;
    })
    .catch((error) => {
      console.log('-------- getOrderExcel catch err ---------->', error.response.data);
      return error.response;
    });
const getRateList = () =>
  apiV1AxiosInstance('/admin-ratelists', {
    method: 'GET',
  })
    .then((response) => {
      // console.log('-------- getRateList res ---------->', response);
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- getRateList catch err ---------->', error.response);
      return error.response;
    });
// ****************** POSTS ********************************

const addOverweight = (labelId, weightDiff, realWeight) =>
  apiV1AxiosInstance('/overweights', {
    method: 'POST',
    data: {
      shipping_id: labelId,
      weight_diff: weightDiff,
      real_weight: realWeight,
      overweight_charged_check: true,
    },
  })
    .then((response) => {
      console.log('-------- addOverweight res ---------->', response);

      return response.data;
    })
    .catch((error) => {
      console.log('-------- addOverweight catch err ---------->', error.response);
      return error.response;
    });
const calculateOverweight = (labelId, weightDiff, realWeight) =>
  apiV1AxiosInstance('/calculate-overweight', {
    method: 'POST',
    data: {
      shipping_id: labelId,
      weight_diff: weightDiff,
      real_weight: realWeight,
    },
  })
    .then((response) => {
      console.log('-------- calculateOverweight res ---------->', response);

      return response.data;
    })
    .catch((error) => {
      console.log('-------- calculateOverweight catch err ---------->', error.response);
      return error.response;
    });
const createOverweightOrder = (formData) =>
  apiV1AxiosInstance('/bulk_overweights', {
    method: 'POST',
    data: formData,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- addOverweight catch err ---------->', error.response.data);
      return error.response;
    });
const addAdditionalCharge = (crmId, identifier, chargeAmount, concept, referenceTrace) =>
  apiV1AxiosInstance('/admin-additional-charges', {
    method: 'POST',
    data: {
      crm_user_id: crmId,
      reference: identifier,
      reference_trace: referenceTrace,
      amount: parseFloat(chargeAmount),
      code_concept: concept,
    },
  })
    .then((response) => {
      // console.log('-------- addAdditionalCharge response ---------->', response);

      return response.data;
    })
    .catch((error) => {
      // console.log('-------- addAdditionalCharge catch err ---------->', error.response.data);
      return error.response;
    });
const addIncreaseBalance = (chargeAmount, concept, crmId, reference) =>
  apiV1AxiosInstance('/admin-additional-charges', {
    method: 'POST',
    data: {
      crm_user_id: crmId,
      reference,
      amount: parseFloat(chargeAmount),
      code_concept: concept,
    },
  })
    .then((response) => {
      // console.log('-------- addIncreaseBalance res ---------->', response);
      return response.data;
    })
    .catch((error) => {
      console.log('-------- addIncreaseBalance catch err ---------->', error.response.data);
      return error.response;
    });
const addComment = (crmId, comment) =>
  apiV1AxiosInstance('/admin-comments', {
    method: 'POST',
    data: {
      user: crmId,
      content: comment,
    },
  })
    .then((response) => {
      //  console.log('-------- addComment res ---------->', response);
      return response.data;
    })
    .catch((error) => {
      console.log('-------- addComment catch err ---------->', error.response.data);
      return error.response;
    });
// ****************** PUT ********************************
const editComment = (crmId, id, comment) =>
  apiV1AxiosInstance(`/admin-comments/${id}`, {
    method: 'put',
    data: {
      user: crmId,
      content: comment,
    },
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- getEditCommnet catch err ---------->', error.response);
      return error.response;
    });
const editUserAccount = (crmId, data) =>
  apiV1AxiosInstance(`/admin-users/${crmId}`, {
    method: 'put',
    data: {
      crm_user_id: data.crmId,
      environment: data.environment,
      email: data.email,
      razon_social: data.razonSocial,
      rates_list_method: data.rateMethod,
      rates_list: data.rateType,
      label_type: data.labelType,
      is_active: data.isAccountActive,
    },
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- editUserAccount catch err ---------->', error.response);
      return error.response;
    });
// ****************** DELETE ********************************
const deleteComment = (id) =>
  apiV1AxiosInstance(`/admin-comments/${id}`, {
    method: 'DELETE',
  })
    .then((response) => {
      console.log('-------- getDeleteCommnet res ---------->', response);
      return response.data;
    })
    .catch((error) => {
      console.log('-------- getDeleteCommnet catch err ---------->', error.response);
      return error.response;
    });

export {
  loginAdmin,
  getAdminShippings,
  getAdminDetailShipping,
  getUserAccounts,
  getChargeConcepts,
  getServicesName,
  getUser,
  getBillingDocs,
  getStatements,
  getOverweights,
  getOverweightOrders,
  getOverweightOrderById,
  getOrderExcel,
  getRateList,
  createOverweightOrder,
  addOverweight,
  calculateOverweight,
  addAdditionalCharge,
  addIncreaseBalance,
  getComments,
  addComment,
  editComment,
  editUserAccount,
  deleteComment,
};
