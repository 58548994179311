import React from 'react';
import { Col, Row, Card, Tooltip, OverlayTrigger } from 'react-bootstrap';
import moment from 'moment';
import CsLineIcons from 'cs-line-icons/CsLineIcons';

const AdditionalChargesCard = ({ data, isAdmin = '' }) => {
  return (
    <Card className="mb-3 px-0">
      <Row className="g-0 h-100 sh-lg-9 position-relative overflow-y-hidden">
        <Col className="py-4 py-lg-0 px-4 h-100 card bg-light" style={{ boxShadow: '0 4px 6px rgba(var(--separator-rgb), 0.5)' }}>
          <Row className="g-0 h-100 align-content-center">
            <Col xs="12" lg="4" className="d-flex flex-column mb-lg-0 mb-2 pe-3 h-lg-100 justify-content-center order-1 bg-infow">
              <div className="text-muted text-small d-lg-none">Concepto</div>
              <div className="text-primary">{typeof data?.statement_text === 'string' && data?.statement_text.toUpperCase()}</div>
            </Col>
            <Col
              xs="6"
              lg="2"
              className="d-flex flex-column mt-4 mb-4 mt-lg-0 mb-lg-0 pe-1 mb-2 mb-lg-0 justify-content-center align-items-lg-start order-2 bg-secondaryw"
            >
              <div className="text-muted text-small d-lg-none">Fecha</div>
              {/* ${data?.created.slice(11, 19)} */}
              <div className="lh-1 text-alternate">{`${moment(data.created).format('DD-MM-YYYY')}`}</div>
            </Col>
            <Col
              xs="6"
              lg="2"
              className="d-flex flex-column pe-1 mb-1 mb-lg-0 align-items-start justify-content-center align-items-end align-items-lg-start order-3 bg-infow"
            >
              <div className="text-muted text-small d-lg-none">Referencia</div>
              <div
                className="text-alternate text-nowrap "
                style={{
                  overflowX: 'scroll',
                  maxWidth: '100%',
                }}
              >
                {!data.reference_trace ? '' : data.reference_trace}
              </div>
            </Col>
            <Col xs="6" lg="2" className="d-flex flex-column mb-1 mb-lg- align-items-start align-items-lg-center justify-content-lg-center order-4 bg-successw">
              <div className="text-muted text-small d-lg-none">Estado</div>
              <div className="text-primary">{data?.refunded ? 'DEVUELTO' : 'APLICADO'}</div>
            </Col>
            <Col xs="12" lg="1" className="pe-lg-1 mb-1 mb-lg-0 d-flex flex-column justify-content-center align-items-end order-last order-lg-5 bg-infow">
              {!data?.refunded && isAdmin && (
                <>
                  <div className="text-muted text-small d-lg-none">Devolver Cargo</div>
                  <OverlayTrigger
                    delay={{ show: 500, hide: 0 }}
                    placement="top"
                    overlay={<Tooltip id="tooltip-top">Devolver Cargo(pronto funcionará)</Tooltip>}
                  >
                    <div onClick={() => console.log('en construccion')}>
                      <CsLineIcons icon="rotate-left" className="anchor-effect-primary cursor-pointer" />
                    </div>
                  </OverlayTrigger>
                </>
              )}
            </Col>
            <Col xs="6" lg="1" className="d-flex flex-column pe-1 mb-4 mb-lg-0 justify-content-center align-items-end order-5 bg-infow">
              <div className="text-muted text-small d-lg-none">Cargo</div>
              <div className={`${data?.refunded ? 'text-success' : 'text-primary'}`}>
                <span className="text-small text-muted">$</span>
                {data.charge}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};
export default AdditionalChargesCard;
