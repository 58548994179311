import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Row, Col, Dropdown, Form, Modal, Button } from 'react-bootstrap';
import { VerticalNavigation, VerticalSection, VerticalItem } from 'react-rainbow-components';
import styled from 'styled-components';

import HtmlHead from 'components/html-head/HtmlHead';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import LoadingCar from '../../../components/loading/LoadingCar';
import LoadingPulse from '../../../components/loading/LoadingPulse';
import Paginate from '../../../components/Paginate';
import CardAddress from './components/CardAddress';
import ModalAddress from './components/ModalAddress';
// api
import { getAddresses, getAddressesDirectory } from '../../../api/Api-user';

const Addresses = () => {
  const title = 'Direcciones';
  const description = 'Direcciones Plataforma Autopaquete';
  // - Local States
  const [addressesList, setAddressesList] = useState(null);
  const [directory, setDirectory] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSelectedAddress, setIsSelectedAddress] = useState(false);
  const [addressNameToSearch, setAddressNameToSearch] = useState('');
  // paginate
  const [totalPages, setTotalPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  // - Use references to do scroll Up
  const startRef = useRef(null);
  // - Global State
  // const { currentUser } = useSelector((state) => state.auth);
  const { addressDetail } = useSelector((state) => state.address_data);
  // const token = currentUser.api_key_production;
  const StyledContainer = styled.div`
    min-width: 220px;
    margin: 5px;
    padding: 0.5rem;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    // REVISAR PORQUE NO TOMA ESTAS PROPIEDADES
    // position: 'absolute';
    // background-color: '#ffffff';
    // z-index: 100;
    > nav > div > ul > li > button {
      // background: salmon;
      padding: 0.5rem;
    }
  `;
  // console.log('direcciones- direccion a detalle en el global:', addressDetail);
  const handlerGetAddresses = async (page, items) => {
    setTimeout(function () {
      setIsSelectedAddress(false); // remove searched address
    }, 1000);
    setAddressNameToSearch('');
    try {
      const result = await getAddresses(page, items);
      setAddressesList(result.address);
      // console.log('result', result);
      setTotalPages(result.total_pages);
      setIsLoading(false);
      // setAddressesList([]);
      startRef.current.scrollIntoView({ behavior: 'smooth' });
    } catch (error) {
      // console.log('error en getAddresses catch :', error);
    }
  };
  useEffect(() => {
    handlerGetAddresses(currentPage, pageSize);
  }, [isModalOpen, currentPage, pageSize]);
  const settingPage = (limit) => {
    setIsLoading(true);
    setCurrentPage(1);
    setPageSize(limit);
  };
  const handlerDirectory = (string) => {
    // console.log('string:', string);
    getAddressesDirectory(string)
      .then((result) => {
        // console.log('resultado peticion addresss directory:', result.address);
        if (result.address?.length >= 1) {
          setDirectory(result.address);
        }
      })
      .catch();
  };
  const showAddress = (address) => {
    // console.log('direccion seleccionada:',address)
    setDirectory(false);
    setIsSelectedAddress(true);
    setAddressesList([address]);
  };
  // console.log(addressesList)
  return (
    <>
      {addressesList === null && <LoadingCar />}
      {isLoading && <LoadingPulse />}
      <div id="pageContainer" onClick={() => setDirectory(false)}>
        <HtmlHead title={title} description={description} />
        <div className="page-title-container" ref={startRef}>
          <Row className="g-0 mb-3 ">
            {/* Title Start col-auto */}
            <Col xs="auto" className="col-auto mb-sm-0 me-auto">
              <NavLink className="muted-link pb-1 d-inline-block hidden breadcrumb-back" to="/">
                <CsLineIcons icon="chevron-left" size="13" />
                <span className="align-middle text-small ms-1">Inicio</span>
              </NavLink>
              <h1 className="mb-0 pb-0 display-4" id="title">
                {title}
              </h1>
            </Col>
            {/* Title End */}
            {/* button Add Address Start */}
            <Col xs="12" sm="auto" className="d-flex align-items-end justify-content-end mt-3 mb-sm-0 order-sm-3">
              {/* d-flex align-items-end justify-content-end mb-2 mb-sm-0 order-sm-3 */}
              <Button
                href="/ajustes/direcciones/agregar"
                variant="outline-primary"
                className="btn-icon w-100 btn btn-icon btn-outline-primary btn-icon-start ms-0 ms-sm-1  w-md-auto"
              >
                <CsLineIcons icon="plus" /> <span> Agregar </span>
              </Button>
            </Col>
            {/* button Add Address End */}
          </Row>
        </div>
        <Row className="mb-1">
          <Col md="5" lg="3" className="mb-1">
            {/* Search Start */}
            <div className="d-inline-block float-md-start me-1 mb-1 search-input-container w-100 shadow bg-foreground">
              <Form.Control
                type="text"
                placeholder="Buscar por nombre"
                value={addressNameToSearch}
                onChange={(e) => {
                  handlerDirectory(e.target.value);
                  setAddressNameToSearch(e.target.value);
                }}
              />
              {!isSelectedAddress ? (
                <span className="search-magnifier-icon">
                  <CsLineIcons icon="search" />
                </span>
              ) : (
                <span className="search-delete-icon" onClick={() => handlerGetAddresses(currentPage, pageSize)}>
                  <CsLineIcons icon="close" />
                </span>
              )}
            </div>
            {/* - Address List Start */}
            {directory && (
              <StyledContainer style={{ position: 'absolute', zIndex: 100, backgroundColor: '#ffffff', marginTop: '40px' }}>
                <VerticalNavigation>
                  <VerticalSection>
                    {directory.map((address) => (
                      <VerticalItem key={address.id} name={address.name} label={address.name} onClick={() => showAddress(address)} />
                    ))}
                  </VerticalSection>
                </VerticalNavigation>
              </StyledContainer>
            )}
            {/* - Address List End */}
            {/* Search End || isSelectedAddress  */}
          </Col>
          <Col xs="12" className="mb-1  text-end">
            {/* Length Start */}
            <Dropdown align={{ xs: 'end' }} className="mb-2 d-inline-block ms-1">
              <Dropdown.Toggle variant="foreground-alternate" className={`shadow sw-20 ${addressesList?.length === 0 || (isSelectedAddress && 'invisible')}`}>
                {`${pageSize} Direcciones`}
              </Dropdown.Toggle>
              <Dropdown.Menu className="shadow dropdown-menu-end">
                <Dropdown.Item className={`${pageSize === 10 && 'd-none'}`} onClick={() => settingPage(10)}>
                  10 Direcciones
                </Dropdown.Item>
                <Dropdown.Item className={`${pageSize === 20 && 'd-none'}`} onClick={() => settingPage(20)}>
                  20 Direcciones
                </Dropdown.Item>
                <Dropdown.Item className={`${pageSize === 50 && 'd-none'}`} onClick={() => settingPage(50)}>
                  50 Direcciones
                </Dropdown.Item>
                <Dropdown.Item className={`${pageSize === 100 && 'd-none'}`} onClick={() => settingPage(100)}>
                  100 Direcciones
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <div className={`text-muted d-lg-none mt-3 ${addressesList?.length === 0 || (isSelectedAddress && 'invisible')}`}>{`Página ${currentPage}`}</div>
          </Col>
        </Row>

        <div style={{ minHeight: '50vh' }} id="cardContainer">
          <Row className="g-0 mb-2 d-none d-lg-flex">
            <Col xs="auto" className={`text-muted mb-3 ${addressesList?.length === 0 || (isSelectedAddress && 'invisible')}`}>
              {`Página ${currentPage}`}
            </Col>
          </Row>
          {/* List Header Start */}
          <Row className="g-0 h-100 align-content-center d-none d-lg-flex ps-5 pe-5 mb-2 custom-sort">
            <Col lg="3" className="d-flex flex-column mb-lg-0 pe-3 d-flex">
              <div className="text-muted text-small cursor-pointer">NOMBRE</div>
            </Col>
            {/* <Col lg="2" className="d-flex flex-column pe-1 justify-content-center">
            <div className="text-muted text-small cursor-pointer">TELEFONO</div>
          </Col> */}
            <Col lg="5" className="d-flex flex-column pe-1 justify-content-center">
              <div className="text-muted text-small cursor-pointer">CALLE</div>
            </Col>
            <Col lg="2" className="d-lg-none d-flex flex-column pe-1 justify-content-center align-items-center">
              <div className="text-muted text-small cursor-pointer">NÚMERO</div>
            </Col>
            <Col lg="3" className="d-flex flex-column pe-1 justify-content-center">
              <div className="text-muted text-small cursor-pointer">COLONIA</div>
            </Col>
            <Col lg="1" className="d-flex flex-column pe-1 justify-content-center align-items-end">
              <div className="text-muted text-small cursor-pointer">C.P</div>
            </Col>
            {/* <Col lg="1" className="d-flex flex-column pe-1 justify-content-center" >
            <div className="text-muted text-small cursor-pointer">CIUDAD</div>
          </Col> */}
          </Row>
          {/* List Header End */}
          {addressesList?.length === 0 ? (
            <div className="py-5 mt-5 d-flex flex-column justify-content-center align-items-center w-50 m-auto">
              <h3 className="text-center mt-5">
                Tu historial de direcciones está vacío.
                <br />
                ¿Quieres agregar una nueva dirección?
              </h3>
              <Button
                href="/ajustes/direcciones/agregar"
                variant="outline-primary"
                className="btn-icon w-100 btn btn-icon btn-outline-primary btn-icon-start ms-0 ms-sm-1  w-md-auto"
              >
                <CsLineIcons icon="plus" /> <span> Agregar </span>
              </Button>
              {/* <img alt="box" src="/img/animation/house.webp" style={{ width: '50px', height: '50px' }} /> */}
            </div>
          ) : (
            <>
              {/* List Addresses Items Start */}
              {addressesList?.map((address) => (
                <CardAddress key={address.id} address={address} setIsModalOpen={setIsModalOpen} />
              ))}
              {/* List Addresses Items End */}
              {/* Address Modal Start */}
              <Modal className="modal-right" show={isModalOpen} onHide={() => setIsModalOpen(false)}>
                <Modal.Header closeButton>
                  <Modal.Title as="h5">Detalle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <ModalAddress addressDetail={addressDetail} setIsModalOpen={setIsModalOpen} />
                </Modal.Body>
              </Modal>
              {/* Address Modal End */}
              {/* Pagination Start */}
              <div className={`d-flex flex-column justify-content-center align-items-center mt-5 mx-auto ${totalPages === 0 && 'd-none'}`}>
                <Paginate currentPage={currentPage} totalPages={totalPages} setCurrentPage={setCurrentPage} setIsLoading={setIsLoading} />
              </div>
              {/* Pagination End */}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Addresses;
