import React, { useState, useEffect, useRef } from 'react';
// import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Row, Col, Dropdown, Form, Button } from 'react-bootstrap';
// import { Application } from 'react-rainbow-components';

import HtmlHead from 'components/html-head/HtmlHead';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { motion } from 'framer-motion';

// components
import ShippingCard from './components/ShippingCard';
import DateModal from './components/DateModal';
import ShippingCSV from '../../../components/reactCsv/ShippingCSV';
import AdminLoader from '../../../components/loading/AdminLoader';
import LoadingPulse from '../../../components/loading/LoadingPulse';
import Paginate from '../../components/Paginate';
// api
import { getAdminShippings } from '../../api/Api-admin';

// const { motion } = require('framer-motion');

const Shippings = () => {
  const title = 'Historial Envíos';
  const description = 'Historial Envíos Plataforma Autopaquete';
  // -Local States
  const [shipmentHistory, setShipmentHistory] = useState([]);
  const [labelId, setLabelId] = useState('');
  const [crmId, setCrmId] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [currentPage, setCurrentPage] = useState(1); // to locally control the component
  const [pageSize, setPageSize] = useState(20); // to locally control the component
  const [totalItems, setTotalItems] = useState(pageSize); // update with the response of request
  const [page, setPage] = useState(pageSize); // update with the response of request ESTE ES PARA LA PAGINACION
  const [totalPages, setTotalPages] = useState(null); // update with the response of request
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [renderDate, setRenderdate] = useState(false);
  const [isInitialLoader, setIsInitialLoader] = useState(true);
  const [isResetBtnActive, setIsResetBtnActive] = useState(false);
  const [trigger, setTrigger] = useState(false);

  // - Use references to do scroll Up
  const startRef = useRef(null);

  // Get All Shippings
  const handlerGetShippings = () => {
    setIsLoading(true);
    getAdminShippings(currentPage, pageSize, crmId, labelId, startDate, endDate)
      .then((response) => {
        console.log('get admin all shippings res:', response);
        if (response.shippings !== undefined) {
          setShipmentHistory(response.shippings);
          setPage(response.current_page);
          setTotalPages(response.total_pages);
          setTotalItems(response.page_size);
          setIsLoading(false);
          setIsInitialLoader(false);

          if (startRef.current) {
            startRef.current.scrollIntoView({ behavior: 'smooth' });
          }
        } else if (response.data.error.admin) {
          toast.error(`Error: $${response.data.error.admin[0]}`, {
            position: 'top-right',
            autoClose: 7000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        } else if (response.status === 500) {
          setIsLoading(false);
          toast.error('Error: No se ha encontrado la guía. Por favor, verifica que el ID de envío sea correcto e inténtalo de nuevo.', {
            position: 'top-right',
            autoClose: 7000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        }
      })
      .catch(() => {
        toast.error('Error: Ha ocurrido un error inesperado. Por favor, inténtalo de nuevo. (catch error)', {
          position: 'top-right',
          autoClose: 7000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
        setIsLoading(false);
      });
  };
  const handlerFilters = () => {
    setIsLoading(true);
    setIsResetBtnActive(true);
    getAdminShippings(currentPage, pageSize, crmId, labelId, startDate, endDate)
      .then((response) => {
        console.log('get admin all shippings FILTER  res:', response);
        if (response.shippings !== undefined) {
          setShipmentHistory(response.shippings);
          setPage(response.current_page);
          setTotalPages(response.total_pages);
          setTotalItems(response.page_size);
          setIsLoading(false);
          if (startRef.current) {
            startRef.current.scrollIntoView({ behavior: 'smooth' });
          }
        } else if (response.data.error.admin) {
          setIsLoading(false);

          toast.error(`Error: ${response.data.error.admin[0]}`, {
            position: 'top-right',
            autoClose: 7000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        }
      })
      .catch(() => {
        toast.error('Error: Ha ocurrido un error inesperado. Por favor, inténtalo de nuevo. (catch error)', {
          position: 'top-right',
          autoClose: 7000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
        setIsLoading(false);
      });
  };
  // Clean Dates
  const clearDates = () => {
    // Re render component
    setRenderdate(!renderDate);
    // clear param request by dates
    setStartDate('');
    setEndDate('');
  };
  // Reset Values
  const resetInitialValues = () => {
    setIsLoading(true);
    setIsResetBtnActive(false);
    setLabelId('');
    setCrmId('');
    clearDates();
    setCurrentPage(1);
    setPageSize(pageSize);
    setTrigger((prev) => !prev);
  };

  useEffect(() => {
    if (crmId || labelId || startDate || endDate) {
      handlerFilters();
    } else {
      handlerGetShippings();
    }
  }, [currentPage, pageSize, trigger]);

  const settingPage = (record) => {
    setPageSize(record);
    setCurrentPage(currentPage);
  };
  // const searchHandler = () => {
  //   if (startDate && endDate) {
  //     handlerGetShippingsByDates();
  //   } else if (crmId && !startDate && !endDate) {
  //     handlerGetShippingsByCrmId();
  //   } else if (labelId) {
  //     handlerGetShippingsByLabelId();
  //   } else {
  //     toast.error('Por favor, completa uno de los buscadores antes de realizar la búsqueda.', {
  //       position: 'top-right',
  //       autoClose: 7000,
  //       hideProgressBar: true,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: 'colored',
  //     });
  //   }
  // };
  // console.log('id envio:', labelId);
  // console.log('rango de fechas:', startDate, '//', endDate);
  // rango de fechas: 2024-08-01 // 2024-08-07
  // const handleKeyDown = (event) => {
  //   if (event.key === 'Enter') {
  //     handlerGetShippingsByCrmId();
  //   }
  // };
  return (
    <>
      {isLoading && <LoadingPulse />}
      {isModalOpen && <DateModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />}

      {isInitialLoader ? (
        <AdminLoader />
      ) : (
        <>
          <HtmlHead title={title} description={description} />
          <div className="page-title-container" ref={startRef}>
            <Row className="g-0">
              {/* Title Start */}
              <Col className="col-auto mb-3 mb-sm-0 me-auto order-1">
                <NavLink className="muted-link pb-1 d-inline-block hidden breadcrumb-back invisible" to="/">
                  <CsLineIcons icon="chevron-left" size="13" />
                  <span className="align-middle text-small ms-1">Envíos</span>
                </NavLink>
                <h1 className="mb-0 pb-0 display-4" id="title">
                  {title}
                </h1>
              </Col>
              {/* Title End */}
            </Row>
          </div>
          {/* este div es para ocultar el contenido cuando esta cargando  */}
          <div style={{ minHeight: '50vh' }} id="cardContainer">
            {/* *** Dropdown and Search Buttons Start *** */}
            <Row className="g-0 mb-3 mt-sm-5">
              {/* Search With Label id Start */}
              <Row className="g-0">
                <Col xs="auto" md="auto" className="mb-2 me-2">
                  <div className="d-inline-block float-md-start pe-1 search-input-container  shadow bg-foreground" style={{ width: '274px' }}>
                    <Form.Control
                      type="text"
                      style={{ fontFamily: 'Montserrat' }}
                      placeholder="Buscar por id envío"
                      value={labelId}
                      onChange={(e) => setLabelId(e.target.value)}
                    />
                  </div>
                </Col>
              </Row>
              {/* Search With Label id End */}
              {/* Search With crm and dates Start */}
              <Row className="g-0">
                <Col xs="auto" md="auto" className="mb-2 me-2">
                  <div className="d-inline-block float-md-start pe-1 search-input-container  shadow bg-foreground" style={{ width: '274px' }}>
                    <Form.Control
                      type="text"
                      // style={{ fontFamily: 'Montserrat' }}
                      placeholder="Buscar por crm id"
                      value={crmId}
                      onChange={(e) => {
                        setCrmId(e.target.value);
                      }}
                    />
                  </div>
                </Col>

                <Col xs="auto" md="auto" className="mb-2 me-2 p-0">
                  {/* <DateModal
                    title="Selecciona un rango de fecha"
                    borderRadius="semi-rounded"
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    setLabelId={setLabelId}
                    key={renderDate}
                    // renderDate={renderDate}
                    // setRenderdate={setRenderdate}
                    resetInitialValues={resetInitialValues}
                  /> */}
                  <DateModal
                    title="Selecciona un rango de fecha"
                    borderRadius="semi-rounded"
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    setLabelId={setLabelId}
                    key={renderDate}
                    // renderDate={renderDate}
                    // setRenderdate={setRenderdate}
                    resetInitialValues={resetInitialValues}
                  />
                </Col>
                <Col xs="auto" md="auto" className="mb-2">
                  <Button variant="primary" style={{ height: '36px', width: 'auto' }} startDate onClick={handlerFilters}>
                    <span>Buscar</span>
                  </Button>
                  {isResetBtnActive && (
                    <Button variant="outline-primary" style={{ height: '36px', width: 'auto', marginLeft: '4px' }} onClick={resetInitialValues}>
                      <span>Regresar</span>
                    </Button>
                  )}
                </Col>
                <hr className="bg-muted" />
              </Row>
              {/* Search With crm Start and dates End */}
            </Row>
            <Row className="g-0 d-flex justify-content-end aling-items-center">
              {/* Excel Buttons Start */}
              <Col xs="auto">{totalPages > 1 && <ShippingCSV shipmentHistory={shipmentHistory} crmId={crmId} />}</Col>
              {/* Excel  Buttons End */}
              <Col xs="auto" className=" p-0 ms-2">
                <Dropdown align={{ xs: 'end' }} className={`d-inline-block ${totalPages < 2 && 'invisible'}`}>
                  <Dropdown.Toggle variant="foreground-alternate" className="shadow sw-15">
                    {`${pageSize} Envios`}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="shadow dropdown-menu-end">
                    <Dropdown.Item className={`${pageSize === 20 && 'd-none'}`} onClick={() => settingPage(20)}>
                      20 Envíos
                    </Dropdown.Item>
                    <Dropdown.Item className={`${pageSize === 50 && 'd-none'}`} onClick={() => settingPage(50)}>
                      50 Envíos
                    </Dropdown.Item>
                    <Dropdown.Item className={`${pageSize === 100 && 'd-none'}`} onClick={() => settingPage(100)}>
                      100 Envíos
                    </Dropdown.Item>
                    <Dropdown.Item className={`${pageSize === 200 && 'd-none'}`} onClick={() => settingPage(200)}>
                      200 Envíos
                    </Dropdown.Item>
                    <Dropdown.Item className={`${pageSize === 300 && 'd-none'}`} onClick={() => settingPage(300)}>
                      300 Envíos
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                {/* Length End */}
              </Col>
            </Row>
            {/* *** Dropdown and Search Buttons End *** */}
            <Row className="g-0 my-3">
              <div className={`text-muted d-flex justify-content-lg-end ${totalPages < 2 && 'invisible'}`}>
                <p className="m-0" style={{ minWidth: '86px' }}>{`Página: ${page}`}</p>
              </div>
              <div className={`text-muted d-flex justify-content-lg-end ${totalPages < 2 && 'invisible'}`}>
                <p className="m-0" style={{ minWidth: '86px' }}>{`Envíos: ${totalItems}`}</p>
              </div>
            </Row>
            {/* *** Main Page Start *** */}
            {/* List Header Start */}
            <Row className="g-0 mb-2 d-none d-lg-flex py-4 py-lg-0 ps-5 pe-4 custom-sort h-100">
              <Col lg="3" className="d-flex flex-column mb-lg-0 pe-3">
                <div className="text-muted text-small cursor-pointer">RAZON SOCIAL</div>
              </Col>
              <Col lg="1" className="d-flex flex-column pe-1 justify-content-center">
                <div className="text-muted text-small cursor-pointer">FECHA / HORA</div>
              </Col>
              <Col lg="3" className="d-flex flex-column align-items-center mb-lg-0 pe-3">
                <div className="text-muted text-small cursor-pointer">ID ENVIO</div>
              </Col>
              <Col lg="3" className="d-flex flex-column pe-1 justify-content-center">
                <div className="text-muted text-small cursor-pointer">SERVICIO</div>
              </Col>
              <Col lg="1" className="d-flex flex-column pe-1 justify-content-center align-items-end">
                <div className="text-muted text-small cursor-pointer">COSTO</div>
              </Col>
              <Col lg="1" className="d-flex flex-column pe-1 align-items-end justify-content-center">
                <div className="invisible text-muted text-small cursor-pointer">MODAL</div>
              </Col>
            </Row>
            {/* List Header End */}
            {/* List shipment Start */}
            {shipmentHistory.length < 1 ? (
              <div className="d-flex flex-column align-items-center mt-lg-5">
                {/* <h3 className="text-center">No se encontraron registros.</h3> */}
                <img alt="sin datos" src="/img/animation/userNotfound.webp" className="w-20" />
              </div>
            ) : (
              shipmentHistory?.map((shipment, index) => <ShippingCard key={index} index={index} data={shipment} />)
            )}
            {/* List shipment End */}
            {/* Pagination Start */}
            <div className={`d-flex flex-column justify-content-center align-items-center mt-5 mx-auto ${totalPages < 2 && 'd-none'}`}>
              <Paginate currentPage={currentPage} totalPages={totalPages} setCurrentPage={setCurrentPage} />
            </div>
            {/* Pagination End */}
          </div>
          {/* *** Main Page End *** */}
        </>
      )}
    </>
  );
};

export default Shippings;
