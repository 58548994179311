import React, { useEffect, useState } from 'react';
import { useHistory, useParams, NavLink } from 'react-router-dom';
import { Row, Col, Button, Card } from 'react-bootstrap';
import HtmlHead from 'components/html-head/HtmlHead';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import moment from 'moment';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// utils
import { initialsUpperCase, startUpperCase } from 'utils/textFormat';
// api
import { getAdminDetailShipping } from '../../api/Api-admin';

// components
import Loading from '../../../components/loading/Loading';
// import LoadingPulse from '../../components/loading/LoadingPulse';
import ShippingModal from './components/ShippingModal';
import AdditionalChargesCard from '../../../components/cards/AdditionalChargesCard';
import AdditionalChargesRow from '../../../components/rows/AdditionalChargesRow';
import OverweightCard from '../../../components/cards/OverweightCard';
import OverweightRow from '../../../components/rows/OverweightRow';

const { FormatMoney } = require('format-money-js');

const fmDecimal = new FormatMoney({
  decimals: 2,
});

const ShippingDetail = () => {
  const title = 'Detalle envío';
  const description = 'Detalle Envío Plataforma Autopaquete';
  const history = useHistory();
  // Local State
  const { labelId, userName, crmId } = useParams();
  const [shipping, setShipping] = useState(null);
  // const [isLoading, setIsLoading] = useState(true);
  const [isModalAdOpen, setIsModalAdOpen] = useState(false);
  const [isModalOwOpen, setIsModalOwOpen] = useState(false);
  const [updatePage, setUpdatePage] = useState(false);

  const handlerGetShipping = () => {
    setUpdatePage(false);
    getAdminDetailShipping(labelId)
      .then((response) => {
        // console.log('get admin Shipping Detail res:', response);
        if (response.shipping_label_id !== undefined) {
          setShipping(response);
          // setIsLoading(false);
        }
        if (response.data.error) {
          // console.log('GUIA NO EXISTENTE');
          history.goBack();
          toast.error(`Error: ${response.data.error.shippings[0]} Código: ${response.data.error_code}`, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
        }
      })
      .catch(() => {
        // console.log(err);
      });
  };

  useEffect(() => {
    handlerGetShipping();
  }, [updatePage]);
  return (
    <>
      {shipping === null && <Loading />}
      {/* {isLoading && <LoadingPulse />} */}
      <div id="pageContainer">
        <HtmlHead title={title} description={description} />
        <div className="page-title-container">
          <Row className="g-0">
            {/* Title Start */}
            <Col className="col-auto mb-3 mb-sm-0 me-auto">
              <div className="muted-link pb-1 d-inline-block hidden breadcrumb-back cursor-pointer" onClick={() => history.goBack()}>
                <CsLineIcons icon="chevron-left" size="13" />
                <span className="align-middle text-small ms-1">Atrás</span>
              </div>
              <h1 className="mb-0 pb-0 display-4" id="title">
                {title}
              </h1>
            </Col>
            {/* Title End */}
            {/* Top Buttons Start */}
            {shipping !== null && (
              <Col xs="12" sm="auto" className="d-flex align-items-end justify-content-end mb-2 mb-sm-0 order-sm-3">
                <a download={shipping.shipping_label_id} href={`data:application/pdf;base64,${shipping.label}`}>
                  <Button variant="outline-primary" className="btn-icon btn-icon-start ms-0 ms-sm-1 w-100 w-md-auto">
                    <CsLineIcons icon="download" /> <span>Descargar Etiqueta</span>
                  </Button>
                </a>
              </Col>
            )}
            {/* Top Buttons End */}
          </Row>
        </div>
        {shipping !== null && (
          <Row>
            <ShippingModal
              data={shipping}
              isModalOpen={isModalAdOpen}
              setIsModalOpen={setIsModalAdOpen}
              title="Cargos Adicionales"
              type="ad"
              setUpdatePage={setUpdatePage}
            />
            <ShippingModal
              data={shipping}
              isModalOpen={isModalOwOpen}
              setIsModalOpen={setIsModalOwOpen}
              title="Sobrepeso"
              type="ow"
              setUpdatePage={setUpdatePage}
            />
            <NavLink className="anchor-effect-primary" to={`/users-all/detail/${crmId}`}>
              <h2 className="cta-1 text-primary mb-5">{userName}</h2>
            </NavLink>
            <Col xl="8" xxl="9">
              {/* Shipping Info Start */}
              <h2 className="small-title">Paquetería</h2>
              <Row className="g-2 mb-5">
                <Col sm="6">
                  <Card className="sh-13 sh-lg-15 sh-xl-14">
                    <Card.Body className="h-100 py-3 d-flex align-items-center">
                      <Row className="g-0 align-items-center">
                        <Col xs="auto" className="pe-3">
                          <div className="border border-primary sw-6 sh-6 rounded-xl d-flex justify-content-center align-items-center">
                            <CsLineIcons icon="plane" className="text-primary" />
                          </div>
                        </Col>
                        <Col>
                          <div className="d-flex align-items-center lh-1-25">Servicio</div>
                          <div className="text-primary">{shipping.shipping_code.replace(/_+/g, ' ')}</div>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
                <Col sm="6">
                  <Card className="sh-13 sh-lg-15 sh-xl-14">
                    <Card.Body className="h-100 py-3 d-flex align-items-center">
                      <Row className="g-0 align-items-center">
                        <Col xs="auto" className="pe-3">
                          <div className="border border-primary sw-6 sh-6 rounded-xl d-flex justify-content-center align-items-center">
                            <CsLineIcons icon="calendar" className="text-primary" />
                          </div>
                        </Col>
                        <Col>
                          <div className="d-flex align-items-center lh-1-25">Fecha Creación</div>
                          <div className="text-primary">{`${moment(shipping.creation_date).format('DD-MM-YY')} ${shipping?.creation_date.slice(11, 19)}`}</div>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
                <Col sm="6">
                  <Card className="sh-13 sh-lg-15 sh-xl-14">
                    <Card.Body className="h-100 py-3 d-flex align-items-center">
                      <Row className="g-0 align-items-center">
                        <Col xs="auto" className="pe-3">
                          <div className="border border-primary sw-6 sh-6 rounded-xl d-flex justify-content-center align-items-center">
                            <CsLineIcons icon="clipboard" className="text-primary" />
                          </div>
                        </Col>
                        <Col>
                          <div className="d-flex align-items-center lh-1-25">ID Envío</div>
                          <div className="text-primary">{shipping.shipping_label_id}</div>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
                <Col sm="6">
                  <Card className="sh-13 sh-lg-15 sh-xl-14">
                    <Card.Body className="h-100 py-3 d-flex align-items-center">
                      <Row className="g-0 align-items-center">
                        <Col xs="auto" className="pe-3">
                          <div className="border border-primary sw-6 sh-6 rounded-xl d-flex justify-content-center align-items-center">
                            <CsLineIcons icon="shipping" className="text-primary" />
                          </div>
                        </Col>
                        <Col>
                          <div className="d-flex align-items-center lh-1-25">Código Rastreo</div>
                          <div className="text-primary">{shipping.shipping_tracking_id}</div>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              {/* Shipping Info End */}
              {/* Add Charge Start */}
              <h2 className="small-title">Agregar Cargos</h2>
              <Card className="mb-5">
                <Card.Body>
                  <Row className="g-0 d-flex justify-content-end mb-lg-2 bg-successb">
                    <Button variant="primary" className="btn-icon btn-icon-start ms-0 mb-3 w-auto" onClick={() => setIsModalOwOpen(true)}>
                      <CsLineIcons icon="warning-hexagon" /> <span>Sobrepeso</span>
                    </Button>
                    <Button variant="primary" className="btn-icon btn-icon-start ms-4 mb-3 mb-sm-0 w-auto" onClick={() => setIsModalAdOpen(true)}>
                      <CsLineIcons icon="wallet" /> <span>Cargo Adiccional</span>
                    </Button>
                  </Row>
                </Card.Body>
              </Card>
              {/* Add Charge End */}
              {/* Resumen Precio Start */}
              <h2 className="small-title">Resumen Precio</h2>
              <Card className="mb-5" style={{ minWidth: '300px' }}>
                <Card.Body className="ps-3 ps-sm-5">
                  <Row className="g-0 mb-3 d-flex flex-column flex-md-row">
                    <Col xs="auto" className="ps-2 pt-3 pb-0 pe-0 h-100">
                      <img src={shipping.service_img} className="card-img rounded-md h-100 sw-13 sw-sm-17 sw-md-18" alt="thumb" />
                    </Col>
                    <Col>
                      <div className="ps-2 ps-sm-4 pt-3 pb-0 pe-0 h-100 ">
                        <Row className="mb-3">
                          {/* Tarifa Base Item */}
                          <Col xs="8" sm="9" className="text-muted">
                            <div>Tarifa Base</div>
                            <div className="text-muted text-small">{`${shipping.parcel.total_weight} KG`}</div>
                          </Col>
                          <Col xs="4" sm="3" className="d-flex justify-content-end align-items-end">
                            <span>
                              <span className="text-small text-muted">$</span>
                              {fmDecimal.from(shipping.shipping_rates?.base)}
                            </span>
                          </Col>
                        </Row>
                        <Row className={`mb-3 ${shipping.shipping_rates.extra_weight === 0 && 'd-none'}`}>
                          {/* Tarifa Sobrepeso Item */}
                          <Col xs="8" sm="9" className="text-muted">
                            <div>Sobrepeso</div>
                          </Col>
                          <Col xs="4" sm="3" className="d-flex justify-content-end align-items-end">
                            <span>
                              <span className="text-small text-muted">$</span>
                              {fmDecimal.from(shipping.shipping_rates?.extra_weight)}
                            </span>
                          </Col>
                        </Row>
                        <Row className={`mb-3 ${shipping.shipping_rates.zonification === 0 && 'd-none'}`}>
                          {/* Tarifa Zonificacion Item */}
                          <Col xs="8" sm="9" className="text-muted">
                            <div>Zonificación</div>
                          </Col>
                          <Col xs="4" sm="3" className="d-flex justify-content-end align-items-end">
                            <span>
                              <span className="text-small text-muted">$</span>
                              {fmDecimal.from(shipping.shipping_rates?.zonification)}
                            </span>
                          </Col>
                        </Row>
                        <Row className={`mb-3 ${shipping.shipping_rates.extended_area === 0 && 'd-none'}`}>
                          {/* Tarifa Zona Extendida Item */}
                          <Col xs="8" sm="9" className="text-muted">
                            <div>Zona extendida</div>
                          </Col>
                          <Col xs="4" sm="3" className="d-flex justify-content-end align-items-end">
                            <span>
                              <span className="text-small text-muted">$</span>
                              {fmDecimal.from(shipping.shipping_rates?.extended_area)}
                            </span>
                          </Col>
                        </Row>
                        <Row className={`mb-3 ${shipping.shipping_rates.insurance === 0 && 'd-none'}`}>
                          {/* Tarifa Seguro Item */}
                          <Col xs="8" sm="9" className="text-muted">
                            <div>Cargo por seguro</div>
                          </Col>
                          <Col xs="4" sm="3" className="d-flex justify-content-end align-items-end">
                            <span>
                              <span className="text-small text-muted">$</span>
                              {fmDecimal.from(shipping.shipping_rates?.insurance)}
                            </span>
                          </Col>
                        </Row>
                        <Row className={`mb-3 ${shipping.shipping_rates.excess_weight === 0 && 'd-none'}`}>
                          {/* Tarifa exceso de peso Item */}
                          <Col xs="8" sm="9" className="text-muted">
                            <div>Tarifa exceso de peso</div>
                          </Col>
                          <Col xs="4" sm="3" className="d-flex justify-content-end align-items-end">
                            <span>
                              <span className="text-small text-muted">$</span>
                              {fmDecimal.from(shipping.shipping_rates?.excess_weight)}
                            </span>
                          </Col>
                        </Row>
                        <Row className={`mb-3 ${shipping.shipping_rates.excess_dimensions === 0 && 'd-none'}`}>
                          {/* Tarifa Exceso Dimensiones Item */}
                          <Col xs="8" sm="9" className="text-muted">
                            <div>Tarifa exceso de dimenciones</div>
                          </Col>
                          <Col xs="4" sm="3" className="d-flex justify-content-end align-items-end">
                            <span>
                              <span className="text-small text-muted">$</span>
                              {fmDecimal.from(shipping.shipping_rates?.excess_dimensions)}
                            </span>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                  <div>
                    <hr />
                    <Row className="g-0 mb-2">
                      <Col xs="auto" className="ms-auto ps-3 text-muted">
                        Subtotal
                      </Col>
                      <Col xs="auto" className="sw-13 text-end">
                        <span>
                          <span className="text-small text-muted">$</span>
                          {fmDecimal.from(shipping.shipping_rates?.subtotal)}
                        </span>
                      </Col>
                    </Row>
                    <Row className="g-0 mb-2">
                      <Col xs="auto" className="ms-auto ps-3 text-muted">
                        IVA
                      </Col>
                      <Col xs="auto" className="sw-13 text-end">
                        <span>
                          <span className="text-small text-muted">$</span>
                          {fmDecimal.from(shipping.shipping_rates?.iva)}
                        </span>
                      </Col>
                    </Row>
                    <Row className="g-0 mb-2">
                      <Col xs="auto" className="ms-auto ps-3 fw-bold">
                        Total
                      </Col>
                      <Col xs="auto" className="sw-13 text-end">
                        <span className="text-primary fw-bold">
                          <span className="text-small text-muted">$</span>
                          {fmDecimal.from(shipping.shipping_rates?.total)}
                        </span>
                      </Col>
                    </Row>
                  </div>
                </Card.Body>
              </Card>
              {/* Resumen Precio End */}
              {/* Additional Charges Start */}
              <h2 className="small-title">Cargos Adicionales</h2>
              <Card className="mb-5">
                <Card.Body>
                  {shipping?.shipping_additional_charges.length === 0 ? (
                    <div className="py-5 mt-5 d-flex flex-column justify-content-center align-items-center w-50 m-auto">
                      <h3 className="text-center mt-5">Sin cargos adicionales</h3>
                    </div>
                  ) : (
                    <>
                      <AdditionalChargesRow />
                      {/* List Charges Items Start */}
                      {shipping?.shipping_additional_charges.map((charge, index) => (
                        <AdditionalChargesCard key={index} data={charge} isAdmin="admin" />
                      ))}
                      {/* List Charges Items End */}
                    </>
                  )}
                </Card.Body>
              </Card>
              {/* Additional Charges End */}
              {/* Overweight Start */}
              <h2 className="small-title">Sobrepesos</h2>
              <Card className="mb-5">
                <Card.Body>
                  {shipping?.shipping_overweights.length === 0 ? (
                    <div className="py-5 mt-5 d-flex flex-column justify-content-center align-items-center w-50 m-auto">
                      <h3 className="text-center mt-5">Sin cargo por sobrepeso</h3>
                    </div>
                  ) : (
                    <>
                      <OverweightRow />
                      {/* List Charges Items Start */}
                      {shipping?.shipping_overweights.map((overweight, index) => (
                        <OverweightCard key={index} data={overweight} isAdmin="admin" />
                      ))}
                      {/* List Charges Items End */}
                    </>
                  )}
                </Card.Body>
              </Card>
              {/* Overweight End */}
            </Col>
            {/* Address Start */}
            <Col xl="4" xxl="3">
              {/* Address Start */}
              <h2 className="small-title">Envío</h2>
              <Card className="mb-5">
                <Card.Body>
                  <div className="mb-5">
                    <p className="text-small text-muted mb-2">DIRECCIÓN DE ORIGEN</p>
                    <Row className="g-0 mb-2">
                      <Col xs="auto">
                        <div className="sw-3 me-1">
                          <CsLineIcons icon="user" size="17" className="text-primary" />
                        </div>
                      </Col>
                      <Col className="text-alternate">{initialsUpperCase(shipping.address_from?.name)}</Col>
                    </Row>
                    {shipping.address_from?.company && (
                      <Row className="g-0 mb-2">
                        <Col xs="auto">
                          <div className="sw-3 me-1">
                            <CsLineIcons icon="building" size="17" className="text-primary" />
                          </div>
                        </Col>
                        <Col className="text-alternate">{startUpperCase(shipping.address_from.company)}</Col>
                      </Row>
                    )}
                    <Row className="g-0 mb-2">
                      <Col xs="auto">
                        <div className="sw-3 me-1">
                          <CsLineIcons icon="pin" size="17" className="text-primary" />
                        </div>
                      </Col>
                      <Col className="text-alternate">
                        {initialsUpperCase(
                          `${shipping.address_from?.street} ${shipping.address_from?.street_number}, ${shipping.address_from?.neighborhood}, ${shipping.address_from?.zip_code}, ${shipping.address_from?.city}, ${shipping.address_from?.state}.`
                        )}
                      </Col>
                    </Row>
                    {shipping.address_from.reference && (
                      <Row className="g-0 mb-2">
                        <Col xs="auto">
                          <div className="sw-3 me-1">
                            <CsLineIcons icon="destination" size="17" className="text-primary" />
                          </div>
                        </Col>
                        <Col className="text-alternate">{startUpperCase(shipping.address_from.reference)}</Col>
                      </Row>
                    )}
                    <Row className="g-0 mb-2">
                      <Col xs="auto">
                        <div className="sw-3 me-1">
                          <CsLineIcons icon="phone" size="17" className="text-primary" />
                        </div>
                      </Col>
                      <Col className="text-alternate">{shipping.address_from?.phone}</Col>
                    </Row>
                  </div>
                  <div className="mb-5">
                    <p className="text-small text-muted mb-2">DIRECCIÓN DE DESTINO</p>
                    <Row className="g-0 mb-2">
                      <Col xs="auto">
                        <div className="sw-3 me-1">
                          <CsLineIcons icon="user" size="17" className="text-primary" />
                        </div>
                      </Col>
                      <Col className="text-alternate">{initialsUpperCase(shipping.address_to?.name)}</Col>
                    </Row>
                    {shipping.address_to?.company && (
                      <Row className="g-0 mb-2">
                        <Col xs="auto">
                          <div className="sw-3 me-1">
                            <CsLineIcons icon="building" size="17" className="text-primary" />
                          </div>
                        </Col>
                        <Col className="text-alternate">{startUpperCase(shipping.address_to?.company)}</Col>
                      </Row>
                    )}

                    <Row className="g-0 mb-2">
                      <Col xs="auto">
                        <div className="sw-3 me-1">
                          <CsLineIcons icon="pin" size="17" className="text-primary" />
                        </div>
                      </Col>
                      <Col className="text-alternate">
                        {initialsUpperCase(
                          `${shipping.address_to?.street} ${shipping.address_to?.street_number}, ${shipping.address_to?.neighborhood}, ${shipping.address_to?.zip_code}, ${shipping.address_to?.city}, ${shipping.address_to?.state}.`
                        )}
                      </Col>
                    </Row>
                    {shipping.address_to?.reference && (
                      <Row className="g-0 mb-2">
                        <Col xs="auto">
                          <div className="sw-3 me-1">
                            <CsLineIcons icon="destination" size="17" className="text-primary" />
                          </div>
                        </Col>
                        <Col className="text-alternate">{startUpperCase(shipping.address_to.reference)}</Col>
                      </Row>
                    )}
                    <Row className="g-0 mb-2">
                      <Col xs="auto">
                        <div className="sw-3 me-1">
                          <CsLineIcons icon="phone" size="17" className="text-primary" />
                        </div>
                      </Col>
                      <Col className="text-alternate">{shipping.address_to.phone}</Col>
                    </Row>
                  </div>
                  <div className="mb-5">
                    <p className="text-small text-muted mb-2">PAQUETE</p>
                    <Row className="g-0 mb-2">
                      <Col xs="auto">
                        <div className="sw-3 me-1">
                          <CsLineIcons icon="edit-square" size="17" className="text-primary" />
                        </div>
                      </Col>
                      <Col className="text-alternate">
                        {startUpperCase(shipping.parcel?.product_description)}
                        <span>{` - Valor $${shipping.parcel?.product_value}`}</span>
                      </Col>
                    </Row>
                    <Row className="g-0 mb-2">
                      <Col xs="auto">
                        <div className="sw-3 me-1">
                          <CsLineIcons icon="box" size="17" className="text-primary" />
                        </div>
                      </Col>
                      <Col className="text-alternate">{`${shipping.parcel?.depth} x ${shipping.parcel.width} x ${shipping.parcel.height} - ${shipping.parcel.weight} kg`}</Col>
                    </Row>
                    <Row className="g-0 mb-2">
                      <Col xs="auto">
                        <div className="sw-3 me-1">
                          <CsLineIcons icon="abacus" size="17" className="text-primary" />
                        </div>
                      </Col>
                      <Col className="text-alternate">{`${shipping.parcel?.volumetric_weight} kg Volumétrico `}</Col>
                    </Row>
                    <Row className="g-0 mb-2">
                      <Col xs="auto">
                        <div className="sw-3 me-1">
                          <CsLineIcons icon="dollar" size="18" className="text-primary" />
                        </div>
                      </Col>
                      <Col className="text-alternate">{`${shipping.parcel?.total_weight} kg Total `}</Col>
                    </Row>
                    {shipping.parcel?.multi_pieces > 1 && (
                      <Row className="g-0 mb-2">
                        <Col xs="auto">
                          <div className="sw-3 me-1">
                            <CsLineIcons icon="print" size="17" className="text-primary" />
                          </div>
                        </Col>
                        <Col className="text-alternate">{`Multi-piezas ${shipping.parcel?.multi_pieces} `}</Col>
                      </Row>
                    )}
                  </div>
                </Card.Body>
              </Card>
              {/* Address End */}
            </Col>
            {/* Address End */}
          </Row>
        )}
      </div>
    </>
  );
};
export default ShippingDetail;
