import React from 'react';
import { Col, Row, Card, Tooltip, OverlayTrigger } from 'react-bootstrap';
import moment from 'moment';
import CsLineIcons from 'cs-line-icons/CsLineIcons';

const OverweightCard = ({ data, isAdmin = '' }) => {
  return (
    <Card className="mb-3 px-0">
      <Row className="g-0 h-100 sh-lg-9 position-relative overflow-y-hidden">
        <Col className="py-4 py-lg-0 px-4 h-100 card bg-light" style={{ boxShadow: '0 4px 6px rgba(var(--separator-rgb), 0.5)' }}>
          <Row className="g-0 h-100 align-content-center">
            <Col
              xs="6"
              lg="2"
              className="mb-4 mb-lg-0 pe-lg-3 h-lg-100 d-flex flex-column justify-content-center align-items-end align-items-lg-start  order-2 order-lg-1 bg-infoW"
            >
              <div className="text-muted text-small d-lg-none">Fecha</div>
              <div className="lh-1 text-alternate">{`${moment(data.created).format('DD-MM-YYYY')}`}</div>
            </Col>
            <Col xs="6" lg="3" className="d-flex flex-column mb-4 mb-lg-0 pe-1 justify-content-center align-items-lg-start order-1 order-lg-2 bg-secondaryW">
              <div className="text-muted text-small d-lg-none">Estado</div>
              <div className="text-primary">{data?.refunded ? 'DEVUELTO' : 'APLICADO'}</div>
            </Col>
            <Col xs="6" lg="2" className="pe-1 mb-4 mb-lg-0 d-flex flex-column justify-content-center align-items-lg-center order-4 order-lg-3 bg-infow">
              <div className="text-muted text-small d-lg-none">Peso real</div>
              <div className="lh-1 text-alternate ps-2">{data.real_kg}</div>
            </Col>
            <Col
              xs="6"
              lg="2"
              className="mb-4 mb-lg-0 ps-lg-4 d-flex flex-column justify-content-lg-center align-items-end align-items-lg-center order-5 order-lg-4 bg-successw"
            >
              <div className="text-muted text-small d-lg-none">Peso declarado</div>
              <div className="lh-1 text-alternate">{data.declared_kg}</div>
            </Col>

            <Col xs="6" lg="2" className="pe-lg-4 mb-1 mb-lg-0 d-flex flex-column justify-content-center align-items-end order-last order-lg-5 bg-infow">
              {!data?.refunded && isAdmin && (
                <>
                  <div className="text-muted text-small d-lg-none">Devolver Cargo</div>
                  <OverlayTrigger
                    delay={{ show: 500, hide: 0 }}
                    placement="top"
                    overlay={<Tooltip id="tooltip-top">Devolver Cargo(pronto funcionará)</Tooltip>}
                  >
                    <div onClick={() => console.log('en construccion')}>
                      {/* ${!isAdmin ? 'visible' : 'invisible'} */}
                      <CsLineIcons icon="rotate-left" className={`anchor-effect-primary cursor-pointer `} />
                    </div>
                  </OverlayTrigger>
                </>
              )}
            </Col>
            <Col
              xs="6"
              lg="1"
              className="pe-1 mb-1 mb-lg-0 d-flex flex-column justify-content-center align-items-star align-items-lg-star order-5 order-lg-last bg-infow"
            >
              <div className="text-muted text-small d-lg-none">Cargo</div>
              <div className={`${data?.refunded ? 'text-success' : 'text-primary'}`}>
                <span className="text-small text-muted">$</span>
                {data.charge}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};
export default OverweightCard;
