import { React, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { NavLink } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { Button, Form } from 'react-bootstrap';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import LayoutFullpage from 'layout/LayoutFullpage';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import HtmlHead from 'components/html-head/HtmlHead';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from 'react-spinners/ClipLoader';
import { login } from '../../api/Api-user';
import { setCurrentUser, setIsLoginUser } from '../../slices/auth/authSlice';

const Login = () => {
  const title = 'Iniciar sesión';
  const description = 'Autopaquete te permitirá gestionar el almacenamiento, inventario y distribución de tu mercancía.';
  const [isLoading, setIsLoading] = useState(false);
  // Check login
  const { isLogin, currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();
  // console.log('* desde loging user obj estado global *:', currentUser);
  // console.log('logout localstorage:', JSON.parse(JSON.parse(localStorage.getItem('persist:starter-project')).auth));
  useEffect(() => {
    if (isLogin) {
      setTimeout(() => {
        history.push('/inicio');
        // toast.success(`¡HOLA ${currentUser.name}!`, {
        //   position: 'top-right',
        //   autoClose: 5000,
        //   hideProgressBar: true,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: 'colored',
        // });
      }, 400);
    }
  }, [isLogin]);
  // End Check login
  const validationSchema = Yup.object().shape({
    email: Yup.string().email('¡Debe ser un correo valido!').required('Correo es obligatorio'),
    password: Yup.string().min(6, '¡Debe tener al menos 6 caracteres!').required('Contraseña es obligatoria'),
  });
  const initialValues = { email: '', password: '' };

  // User Authentication
  const onSubmit = async (values) => {
    setIsLoading(true);
    let message = 'Lo sentimos hubo un error inesperado, favor de intentar más tarde Código: LOGIN-500'; // se ejecuta en el catch
    login(values.email, values.password)
      .then((response) => {
        if (response.user_name !== undefined) {
          // console.log('login user response:', response);
          dispatch(
            setCurrentUser({
              name: response.user_name,
              thumb: response.user_image,
              crmId: response.crm_user_id,
              api_key_production: response.api_key_production,
              api_key_sandbox: response.api_key_sandbox,
              multipieces_production: response.multipieces_production,
              multipieces_sandbox: response.multipieces_sandbox,
              role: response.role, // user
              email: response.email_production,
              razon_social: response.razon_social,
              // email_sandbox: response.email_sandbox,
            })
          );
          dispatch(setIsLoginUser(true));
        } else {
          if (response.error !== undefined) {
            const errorList = response.error.authentication;
            errorList.forEach((error) => {
              message = `${error} Código: ${response.error_code}`;
            });
          }
          toast.error(message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        }
      })
      .catch(() => {
        toast.error(message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      });
    setIsLoading(false);
  };
  // End User Authentication
  const formik = useFormik({ initialValues, validationSchema, onSubmit });
  const { handleSubmit, handleChange, values, touched, errors } = formik;
  const leftSide = (
    <div className="min-h-100 d-flex align-items-center">
      <div className="w-100 w-lg-75 w-xxl-50">
        <div>
          <div className="mb-5 logo-title-white" />
          <div className="mb-5">
            <h1 className="display-login text-white">PLATAFORMA PARA LA GESTIÓN DE TUS ENVÍOS</h1>
          </div>
          <p className="h6 text-white lh-1-5 mb-5">Autopaquete te permitirá gestionar el almacenamiento, inventario y distribución de tu mercancía.</p>
        </div>
      </div>
    </div>
  );

  const rightSide = (
    <div className="sw-lg-70 min-h-100 bg-foreground d-flex justify-content-center align-items-center shadow-deep py-5  full-page-content-right-border">
      <div className="sw-lg-50 px-5">
        {/* <div className="logo-default" /> */}
        <div className="mb-5">
          <h2 className="cta-1 mb-0 text-primary">¡Bienvenido</h2>
          <h2 className="cta-1 text-primary">a nuestra plataforma!</h2>
        </div>
        <div className="mb-5">
          <p className="h6">Utiliza tus credenciales para iniciar sesión.</p>
          {/* <p className="h6">
            If you are not a member, please <NavLink to="/register">register</NavLink>.
          </p> */}
        </div>
        <div>
          <form id="loginForm" className="tooltip-end-bottom" onSubmit={handleSubmit}>
            <div className="mb-3 filled form-group tooltip-end-top">
              <CsLineIcons icon="email" />
              <Form.Control type="text" name="email" placeholder="Correo" value={values.email} onChange={handleChange} />
              {errors.email && touched.email && <div className="d-block invalid-tooltip">{errors.email}</div>}
            </div>
            <div className="mb-3 filled form-group tooltip-end-top">
              <CsLineIcons icon="lock-off" />
              <Form.Control type="password" name="password" onChange={handleChange} value={values.password} placeholder="Contraseña" />
              {/* <NavLink className="text-small position-absolute t-3 e-3" to="/forgot-password">
                Forgot?
              </NavLink> */}
              {errors.password && touched.password && <div className="d-block invalid-tooltip">{errors.password}</div>}
            </div>
            <Button size="lg" type="submit" disabled={isLoading}>
              {isLoading && <ClipLoader color="#FFFFFF" loading={isLoading} size={10} />}
              {isLoading ? ' Cargando' : ' Iniciar sesión'}
            </Button>
          </form>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <HtmlHead title={title} description={description} />
      <LayoutFullpage left={leftSide} right={rightSide} />
    </>
  );
};

export default Login;
