import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';
// components
import LoadingDots from '../../../../components/loading/LoadingRed';
import InputTextFetchData from '../../../../components/form-elements/InputTextFetchData';
import { ToastSuccess, ToastErrorApi, ToastErrorCath } from '../../../../components/alerts/toast';

import { addOverweight, calculateOverweight, getAdminDetailShipping } from '../../../api/Api-admin';
// DATOS QUE SIEMPRE SE REQUIEREN PARA USAR EL COMPONENTE FETCH DATA
// const [isGettingData, setIsGettingData] = useState(false);
// const [isRequestError, setIsRequestError] = useState(false);
// const [requestError, setRequestError] = useState('');
// cleanError() y getShippingData() y handleRequestError() y cleanRequestError()
const OverweightModal = ({ isModalOpen, setIsModalOpen, setUpdatePage }) => {
  const initialValues = {
    id: '',
    serviceName: '',
    userName: '',
    depth: '',
    width: '',
    height: '',
    weight: '',
    volumetricWeight: '',
    totalWeight: '',
    realWeight: '',
    weightDiff: '',
    overweight: {},
  };
  const [shipping, setShipping] = useState(initialValues);
  const [error, setError] = useState('');
  const [prevOverweightCharge, setPrevOverweightCharge] = useState(0);
  const [isCalculateOverweight, setIsCalculateOverweight] = useState(false);
  const [calculateOverweightError, setCalculateOverweightError] = useState('');
  const [isCalculateOverweightError, setIsCalculateOverweightError] = useState(false);
  const [prevRealWeight, setPrevRealWeight] = useState('-');
  const [isGettingData, setIsGettingData] = useState(false);
  const [isRequestError, setIsRequestError] = useState(false);
  const [requestError, setRequestError] = useState('');
  const [isShippingOk, setIsShippingOk] = useState(false);

  // - Data Validation
  const updateField = (data, validation, field) => {
    // Eliminar espacios y caracteres especiales
    const cleanedData = data.replace(/[^a-zA-Z0-9 ]/g, '').trim();
    if (validation.test(cleanedData)) {
      setShipping({
        ...shipping,
        [field]: cleanedData,
      });
    } else {
      setShipping({
        ...shipping,
        [field]: '', // Limpiar el valor si no pasa la validación
      });
    }
  };
  const closeModal = () => {
    // Reset to initial values
    setShipping(initialValues);
    setPrevOverweightCharge(0);
    setError('');
    setIsModalOpen(false);
  };
  const closeModalAndRenderData = () => {
    closeModal();
    setUpdatePage(true);
  };
  //  To handle api errors
  const handleRequestError = (errorMessage) => {
    setIsRequestError(true);
    setRequestError(errorMessage);
  };
  //  To reset handle api errors
  const cleanRequestError = () => {
    setIsRequestError(false);
    setRequestError('');
  };
  //  To handle api calculate Overweight error
  const handleCalculateOverweightError = (msg) => {
    setIsCalculateOverweightError(true);
    setCalculateOverweightError(msg);
  };
  //  To reset api calculate Overweight error
  const cleanCalculateOverweightError = () => {
    setIsCalculateOverweightError(false);
    setCalculateOverweightError('');
  };
  // - Form autofill
  const getShippingData = async (e) => {
    const inputValue = e.target.value;
    const cleanedInputValue = inputValue.replace(/[^a-zA-Z0-9 ]/g, '').trim();
    const key = e.nativeEvent.inputType;
    setIsGettingData(true);
    // console.log('valores antes de actualizar', pickup);
    // If the user is deleting
    if (key === 'deleteContentBackward' || key === 'deleteContentForward') {
      setIsGettingData(false);
      return;
    }
    try {
      if (inputValue.length > 5) {
        const response = await getAdminDetailShipping(cleanedInputValue);
        console.log('getAdminDetailShipping response:', response);
        if (response.shipping_code !== undefined) {
          setIsShippingOk(true); // to show div data
          cleanRequestError();
          // Set states with data from firebase Start
          setShipping({
            ...shipping,
            id: cleanedInputValue,
            serviceName: response.shipping_code,
            userName: response.razon_social,
            depth: response.parcel.depth,
            width: response.parcel.width,
            height: response.parcel.height,
            weight: response.parcel.weight,
            volumetricWeight: response.parcel.volumetric_weight,
            totalWeight: response.parcel.total_weight,
            overweight: response.shipping_overweights[0],
          });
        }
        if (response.data.error) {
          setIsShippingOk(false);
          handleRequestError(response.data.error.admin[0]);
          setShipping({
            ...shipping,
            id: cleanedInputValue,
            serviceName: '',
            userName: '',
            depth: '',
            width: '',
            height: '',
            weight: '',
            volumetricWeight: '',
            totalWeight: '',
            overweight: '',
          });
        }
      } else {
        cleanRequestError();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsGettingData(false);
    }
  };
  // const cleanError = (value, field) => {
  //   // setPickupErr({ ...pickupErr, [field]: value });
  // };

  const handleCalculateOverweight = async () => {
    if (!shipping.weightDiff) {
      cleanCalculateOverweightError();
      setPrevOverweightCharge(0);
      return; // Si no hay diferencia de peso, salimos temprano.
    }

    try {
      setIsCalculateOverweight(true);
      cleanCalculateOverweightError(); // Resetear el error antes de cada ejecución
      const response = await calculateOverweight(shipping.id, shipping.weightDiff, shipping.realWeight);
      const overweightInfo = response.overweight_charge_info;
      // console.log('calculate overweight res:', response);
      if (overweightInfo) {
        cleanCalculateOverweightError();
        setPrevOverweightCharge(overweightInfo.overweight_charge_with_iva || 0);
        setPrevRealWeight(overweightInfo.real_weight || '-');
      } else if (response.data.error?.overweights?.[0]) {
        setPrevOverweightCharge(0);
        setPrevRealWeight('-');
        handleCalculateOverweightError(response.data.error.overweights[0]);
      }
    } catch {
      // En caso de error en la petición o ejecución
      console.error('Error calculating overweight:');
      handleCalculateOverweightError('An unexpected error occurred.');
      // ToastErrorCath(); PORQUE CAE AQUI?
    } finally {
      setIsCalculateOverweight(false); // Esto siempre se ejecuta al final
    }
  };
  const handlerAddOverweight = async () => {
    try {
      if (shipping.weightDiff) {
        // console.log(`agregar sobrpesos de ${overweight} kg, en la guai numeror: ${labelId}`);
        const response = await addOverweight(shipping.id, shipping.weightDiff, shipping.realWeight);
        console.log('add overweight res:', response);
        if (response.overweight_charge !== undefined) {
          ToastSuccess('Se agrego sobrepeso correctamente');
          closeModalAndRenderData();
        }
        if (response.data.error) {
          // setError(`Error: ${response.data.error.overweights[0]}`);
          ToastErrorApi(response.data.error.overweights, response.data.error_code);
        }
      } else {
        ToastErrorCath('Error: Diferencia de kilos es requerido');
      }
    } catch {
      //  ToastErrorCath();
      console.log('error catch');
    }
  };
  useEffect(() => {
    handleCalculateOverweight();
  }, [shipping.weightDiff]);
  useEffect(() => {
    if (shipping.id.length === 0) {
      setIsShippingOk(false);
      cleanRequestError();
      // Reset to initial values
      setShipping(initialValues);
    }
  }, [shipping.id]);
  return (
    <Modal show={isModalOpen} onHide={() => closeModal()}>
      <Modal.Header closeButton>
        <Modal.Title>Sobrepeso</Modal.Title>
      </Modal.Header>
      <Modal.Body className="">
        <div>
          <InputTextFetchData
            label="ID Envío"
            icon="shipping"
            maxLength=""
            placeholder=""
            value={shipping.id}
            isRequired="false"
            isError={isRequestError}
            error={requestError}
            setValues={getShippingData}
            isLoading={isGettingData}
            fieldName="id"
            validation={/^[A-Z0-9 ]+$/i}
            setField={updateField}
            // cleanError={cleanError}
          />
          <div className={`bg-infoS w-100 flex-column flex-sm-row my-3 ${isShippingOk ? 'd-flex' : 'd-none'}`}>
            <span className="w-60">
              <p className="text-small text-muted mb-2">ENVIO</p>
              <div className="d-flex align-items-center lh-1-25">Usuario</div>
              {/* {data.razon_social.toUpperCase()} */}
              <div className="text-primary">{shipping.userName}</div>
              <div className="d-flex align-items-center lh-1-25 ">Servicio</div>
              <div className="text-primary">{shipping.serviceName}</div>
              <p className="text-small text-muted mt-2 mb-1">SOBREPESO</p>
              {shipping.overweight?.charge !== undefined ? (
                <>
                  <div className="d-flex align-items-center lh-1-25 ">Cargo</div>
                  <div className="text-primary">{`$ ${shipping.overweight?.charge}`}</div>
                  <div className="d-flex align-items-center lh-1-25 ">Peso Real</div>
                  <div className="text-primary">{`${shipping.overweight?.real_kg} kg`}</div>
                </>
              ) : (
                <>
                  <div className={`d-flex align-items-center lh-1-25 ${prevOverweightCharge > 0 ? 'visible' : 'invisible'}`}>Cargo a cobrar</div>
                  <div className={`text-primary ${prevOverweightCharge > 0 ? 'visible' : 'invisible'}`}>
                    {isCalculateOverweight ? <LoadingDots /> : `$ ${prevOverweightCharge}`}
                  </div>
                  <div className={`d-flex align-items-center lh-1-25 ${prevOverweightCharge > 0 ? 'visible' : 'invisible'}`}>Peso Real a cobrar</div>
                  <div className={`text-primary ${prevOverweightCharge > 0 ? 'visible' : 'invisible'}`}>{`${prevRealWeight} kg`}</div>
                </>
              )}
            </span>

            <span className="w-40 ps-sm-4">
              <p className="text-small text-muted mb-2 pt-4 pt-sm-0">PAQUETE</p>
              <div className="d-flex align-items-center lh-1-25">Medidas</div>
              <div className="text-primary">{`${shipping.depth} x ${shipping.width} x ${shipping.height} `}</div>
              <div className="d-flex align-items-center lh-1-25 mt-sm-4">Peso declarado</div>
              <div className="text-primary">
                <span className="text-medium text-alternate pe-2"> Físico:</span>
                {`${shipping.weight} kg`}
              </div>
              <div className="text-primary">
                <span className="text-medium text-alternate pe-3"> Volumétrico:</span>
                {`${shipping.volumetricWeight} kg`}
              </div>
              <div className="text-primary">
                <span className="text-medium text-alternate pe-3"> Final:</span>
                {`${shipping.totalWeight} kg`}
              </div>
            </span>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex flex-column align-items-center align-items-sm-end">
        <div className="d-flex justify-content-center justify-content-sm-end">
          <Form.Control
            type="text"
            style={{ fontFamily: 'Montserrat' }}
            className="nav-shadow w-50 me-2"
            placeholder="Peso Real"
            readOnly
            value={shipping.realWeight}
            onChange={(e) => updateField(e.target.value, /^[A-Z0-9 ]+$/i, 'realWeight')}
          />
          <Form.Control
            type="text"
            style={{ fontFamily: 'Montserrat' }}
            className="nav-shadow w-50 me-2"
            placeholder="Diferencia kg"
            value={shipping.weightDiff}
            onChange={(e) => updateField(e.target.value, /^[A-Z0-9 ]+$/i, 'weightDiff')}
          />
          <Button variant="primary" className="w-sm-auto" onClick={handlerAddOverweight}>
            Aplicar
          </Button>
        </div>
        <div className="text-primary text-medium">{error}</div>
        <div className={`text-primary text-medium ${isCalculateOverweightError ? 'visible' : 'invisible'}`}>{calculateOverweightError}</div>
      </Modal.Footer>
    </Modal>
  );
};

export default OverweightModal;
