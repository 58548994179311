import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Dropdown } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// components
import LoadingDots from '../../../../components/loading/LoadingRed';
import { ToastSuccess, ToastErrorApi, ToastErrorCath } from '../../../../components/alerts/toast';

import { calculateOverweight, addOverweight, addAdditionalCharge, getChargeConcepts } from '../../../api/Api-admin';

// NOTA 1: Actualizar peticion y errores de cargos adicionales
// NOTA 2: Revisar por que cae en catch en agregar sobrepeso
// NOTA 3: shipping_overweights = [] propiedad que solo viene si el arary es vacio
// NO puedo mostrar aqui si ya tiene sobrepeso

const ShippingModal = ({ data, isModalOpen, setIsModalOpen, title, type, setUpdatePage }) => {
  const labelId = data.shipping_label_id;
  const crmId = data.crm_user;

  const [chargeConceptList, setChargeConceptList] = useState([]);
  const [nameConcept, setNameConcept] = useState('Concepto');
  const [codeConcept, setCodeConcept] = useState('');
  // const [overweight, setOverweight] = useState('');
  const [weightDiff, setWeightDiff] = useState('');
  const [realWeight, setRealWeight] = useState('');
  const [prevOverweightCharge, setPrevOverweightCharge] = useState(0);
  const [isCalculateOverweight, setIsCalculateOverweight] = useState(false);
  const [calculateOverweightError, setCalculateOverweightError] = useState('');
  const [isCalculateOverweightError, setIsCalculateOverweightError] = useState(false);
  const [prevRealWeight, setPrevRealWeight] = useState('-');
  const [chargeAmount, setChargeAmount] = useState('');
  const [error, setError] = useState('');
  const [referenceTrace, setReferenceTrace] = useState('');

  const updateField = (value) => {
    if (value === '' || /^\d*\.?\d*$/.test(value)) {
      if (type === 'ow') {
        // setOverweight(value);
        setWeightDiff(value);
      } else if (type === 'ad') {
        setChargeAmount(value);
      }
    }
    setError('');
  };
  const closeModal = () => {
    setWeightDiff('');
    setChargeAmount('');
    setNameConcept('Concepto');
    setCodeConcept('');
    setIsModalOpen(false);
    setUpdatePage(true);
  };
  //  To handle api calculate Overweight error
  const handleCalculateOverweightError = (msg) => {
    setIsCalculateOverweightError(true);
    setCalculateOverweightError(msg);
  };
  //  To reset api calculate Overweight error
  const cleanCalculateOverweightError = () => {
    setIsCalculateOverweightError(false);
    // cleanCalculateOverweightError(''); // Resetear el error antes de cada ejecución
  };
  const handleCalculateOverweight = async () => {
    if (!weightDiff) {
      cleanCalculateOverweightError();
      setPrevOverweightCharge(0);
      return; // Si no hay diferencia de peso, salimos temprano.
    }

    try {
      setIsCalculateOverweight(true);
      cleanCalculateOverweightError(); // Resetear el error antes de cada ejecución

      const response = await calculateOverweight(labelId, weightDiff, realWeight);
      const overweightInfo = response.overweight_charge_info;

      if (overweightInfo) {
        cleanCalculateOverweightError();
        setPrevOverweightCharge(overweightInfo.overweight_charge_with_iva || 0);
        setPrevRealWeight(overweightInfo.real_weight || '-');
      } else if (response.data?.error?.overweights?.[0]) {
        setPrevOverweightCharge(0);
        setPrevRealWeight('-');
        handleCalculateOverweightError(response.data.error.overweights[0]);
      }
    } catch {
      // En caso de error en la petición o ejecución
      console.error('Error calculating overweight:');
      handleCalculateOverweightError('An unexpected error occurred.');
      ToastErrorCath();
    } finally {
      setIsCalculateOverweight(false); // Esto siempre se ejecuta al final
    }
  };
  const handlerAddOverweight = async () => {
    try {
      if (weightDiff) {
        // console.log(`agregar sobrpesos de ${overweight} kg, en la guai numeror: ${labelId}`);
        const response = await addOverweight(labelId, weightDiff, realWeight);
        console.log('add overweight res:', response);

        if (response.overweight_charge !== undefined) {
          ToastSuccess('Se agrego sobrepeso correctamente');
          closeModal();
        }
        if (response.data.error) {
          ToastErrorApi(response.data.error.overweights, response.data.error_code);
          // setError(`Error: ${response.data.error.overweights[0]}`);
        }
      } else {
        ToastErrorCath('Error: Diferencia de kilos es requerido');
      }
    } catch {
      // ToastErrorCath(); PORQUE CAE AQUI?
      console.log('error catch');
    }
  };
  const handlerAddCharges = () => {
    // console.log('DATOS CA', crmId, labelId, chargeAmount, codeConcept, referenceTrace);
    if (chargeAmount && codeConcept) {
      // Validar si nameConcept es 'Retorno Guia' y si referenceTrace está vacío
      if (nameConcept === 'Retorno Guia' && !referenceTrace) {
        toast.error('El campo referencia es obligatorio para el concepto "Retorno Guia".', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
        return; // Detenemos la ejecución si la validación falla
      }
      addAdditionalCharge(crmId, labelId, chargeAmount, codeConcept, referenceTrace).then((response) => {
        console.log('agregar cargo res:', response);
        if (response.client_id !== undefined) {
          toast.success(`Se aplico cargo correctamente ⭐`, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
          closeModal();
        }
      });
    } else {
      toast.error('Error: Concepto y monto son requeridos', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
    }
  };
  useEffect(() => {
    // REDUCES_BALANCE   INCREASES_BALANCE
    if (type === 'ad') {
      getChargeConcepts('REDUCES_BALANCE').then((response) => {
        setChargeConceptList(response.list);
      });
    }
  }, [type]);
  useEffect(() => {
    handleCalculateOverweight();
  }, [weightDiff]);
  return (
    <Modal show={isModalOpen} onHide={() => closeModal()}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column flex-sm-row">
          <span className="w-sm-50">
            <p className="text-small text-muted mb-2">ENVIO</p>
            <div className="d-flex align-items-center lh-1-25">Usuario</div>
            <div className="text-primary">{data.razon_social.toUpperCase()}</div>
            <div className="d-flex align-items-center lh-1-25 ">ID Envío</div>
            <div className="text-primary">{labelId}</div>
            <div className="d-flex align-items-center lh-1-25 ">Servicio</div>
            <div className="text-primary">{data.shipping_code.replace(/_+/g, ' ')}</div>
            {nameConcept === 'Retorno Guia' && (
              <>
                <div className="d-flex align-items-center lh-1-25 ">ID Envío Retrono</div>
                <Form.Control
                  type="text"
                  style={{ fontFamily: 'Montserrat' }}
                  className="nav-shadow me-2  my-3 my-sm-0"
                  value={referenceTrace}
                  onChange={(e) => {
                    setReferenceTrace(e.target.value);
                  }}
                />
              </>
            )}
            {type === 'ow' && <p className="text-small text-muted mt-2 mb-1">SOBREPESO</p>}
            {type === 'ow' && (
              <>
                <div className={`d-flex align-items-center lh-1-25 ${prevOverweightCharge > 0 ? 'visible' : 'invisible'}`}>Cargo a cobrar</div>
                <div className={`text-primary ${prevOverweightCharge > 0 ? 'visible' : 'invisible'}`}>
                  {isCalculateOverweight ? <LoadingDots /> : `$ ${prevOverweightCharge}`}
                </div>
                <div className={`d-flex align-items-center lh-1-25 ${prevOverweightCharge > 0 ? 'visible' : 'invisible'}`}>Peso Real a cobrar</div>
                <div className={`text-primary ${prevOverweightCharge > 0 ? 'visible' : 'invisible'}`}>{`${prevRealWeight} kg`}</div>
              </>
            )}
          </span>
          <span className="w-sm-50 ps-sm-4">
            <p className="text-small text-muted mb-2 pt-4 pt-sm-0">PAQUETE</p>
            <div className="d-flex align-items-center lh-1-25">Medidas</div>
            <div className="text-primary">{`${data.parcel?.depth} x ${data.parcel?.width} x ${data.parcel?.height} `}</div>
            <div className="d-flex align-items-center lh-1-25 mt-sm-4">Peso declarado</div>
            <div className="text-primary">
              <span className="text-medium text-alternate pe-2"> Físico:</span>
              {`${data.parcel.weight} kg`}
            </div>
            <div className="text-primary">
              <span className="text-medium text-alternate pe-3"> Volumétrico:</span>
              {`${data.parcel.volumetric_weight} kg`}
            </div>
            <div className="text-primary">
              <span className="text-medium text-alternate pe-3"> Final:</span>
              {`${data.parcel.total_weight} kg`}
            </div>
          </span>
        </div>
      </Modal.Body>
      {/* py-4 ps-2 */}
      {/* flex-column flex-sm-row  align-items-center align-items-sm-end */}
      <Modal.Footer className="d-flex flex-column align-items-center align-items-sm-end py-3 ps-0">
        <div className="d-flex flex-column flex-sm-row align-items-center align-items-sm-end">
          {type === 'ad' && (
            <>
              <Dropdown className="d-inline-block me-2">
                <Dropdown.Toggle variant="foreground-alternate" className="shadow sw-auto" style={{ minWidth: '246px' }}>
                  {nameConcept}
                </Dropdown.Toggle>
                <Dropdown.Menu className="shadow " style={{ maxHeight: '250px', overflowY: 'scroll', fontFamily: 'Montserrat' }}>
                  {chargeConceptList.length > 0 &&
                    chargeConceptList.map((element, index) => (
                      <Dropdown.Item
                        key={index}
                        onClick={() => {
                          setNameConcept(element.name_concept);
                          setCodeConcept(element.code_concept);
                        }}
                      >
                        {element.name_concept}
                      </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
              </Dropdown>
              <Form.Control
                type="text"
                style={{ fontFamily: 'Montserrat' }}
                className="nav-shadow me-2 w-90 my-3 my-sm-0"
                placeholder="$0"
                value={chargeAmount}
                onChange={(e) => {
                  updateField(e.target.value);
                }}
              />
            </>
          )}
          {type === 'ow' && (
            <>
              {/* me-sm-2 d-flex flex-column align-items-sm-end */}

              <div>
                {/* <Form.Label className="ms-2">Diferencia</Form.Label> */}
                <Form.Control
                  type="text"
                  style={{ fontFamily: 'Montserrat' }}
                  className="nav-shadow w-90 w-sm-70 mb-3 ms-2 mb-sm-0"
                  placeholder="Peso Real"
                  readOnly
                  value={realWeight}
                  onChange={(e) => updateField(e.target.value)}
                />
              </div>
              <div>
                {/* <Form.Label className="ms-2">Diferencia</Form.Label> */}
                <Form.Control
                  type="text"
                  style={{ fontFamily: 'Montserrat' }}
                  className="nav-shadow w-90 w-sm-70 mb-3 ms-2 mb-sm-0"
                  placeholder="Diferencia kg"
                  value={weightDiff}
                  onChange={(e) => updateField(e.target.value)}
                />
              </div>
            </>
          )}
          <Button variant="primary" className="w-90 w-sm-auto" onClick={type === 'ow' ? handlerAddOverweight : handlerAddCharges}>
            Aplicar
          </Button>
        </div>

        <div className="text-primary text-medium">{error}</div>
        <div className={`text-primary text-medium ${isCalculateOverweightError ? 'visible' : 'invisible'}`}>{calculateOverweightError}</div>
      </Modal.Footer>
    </Modal>
  );
};

export default ShippingModal;
