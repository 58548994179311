import { apiV1AxiosInstanceLogin, apiV1AxiosInstance, msZipCodeAxiosInstance, checkClabeAxiosInstance, apiV1AxiosInstanceForm } from './http';
import { toUpperIfString } from '../utils/textFormat';
// Base URL:https://api.autopaquete.com.mx/v1

const login = (email, password) =>
  apiV1AxiosInstanceLogin(`/login`, {
    method: 'POST',
    data: {
      user_email: email,
      password,
    },
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });

// ****************  GETS  ******************************

const getCounters = (url) =>
  apiV1AxiosInstance(url, { method: 'GET' })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- getCounter catch err ---------->', error.response);
      return error.response?.data;
    });
const getLastShipping = async () => {
  // Función para verificar si hay conexión a Internet
  // function isOnline() {
  //   return navigator.onLine;
  // }
  // Verificar la conexión antes de hacer la petición
  // if (!isOnline()) {
  //   console.log('No hay conexión a Internet. Cancelando la petición...');
  //   return Promise.reject(new Error('No hay conexión a Internet.'));
  // }

  // Crear un cancel token para poder cancelar la petición
  // const source = axios.CancelToken.source();

  // Función para manejar la pérdida de conexión
  // const handleOffline = () => {
  //   console.log('Desconectado de Internet. Cancelando la petición...');
  //   source.cancel('No hay conexión a Internet.');
  //   window.removeEventListener('offline', handleOffline); // Remover el listener para evitar múltiples llamadas
  // };

  // window.addEventListener('offline', handleOffline);
  try {
    const response = await apiV1AxiosInstance('shippings?page=1&page_size=5', { method: 'GET' });
    return response.data;
  } catch (error) {
    // console.log('-------- getShippings api err ---------->', error.response.data);
    return error;
  }
};
const getAccountSTP = (crmId) =>
  checkClabeAxiosInstance({
    method: 'POST',
    data: {
      crm_id: crmId,
    },
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- getSTPaccount catch err ---------->', error.response.data);
      return error;
    });
const getZipCode = (cp) =>
  msZipCodeAxiosInstance(`/${cp}/`, {
    method: 'GET',
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response;
    });
const getRates = (zipFrom, zipTo, parcel) =>
  apiV1AxiosInstance(`/rates`, {
    method: 'POST',
    timeout: 20000,
    data: {
      zip_from: zipFrom,
      zip_to: zipTo,
      parcel,
    },
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- getRates catch err ---------->', error.response);
      //  error.response.data = {error:[], error_code:""}
      // error.response = { config: , data:{error:[], error_code:""} , headers: , request: ,status: ,statusText: , etc..}
      return error.response;
    });
const getShippingHistory = async (num, limit) => {
  // Función para verificar si hay conexión a Internet
  // function isOnline() {
  //   return navigator.onLine;
  // }
  // Verificar la conexión antes de hacer la petición
  // if (!isOnline()) {
  //   console.log('No hay conexión a Internet. Cancelando la petición...');
  //   return Promise.reject(new Error('No hay conexión a Internet.'));
  // }

  // Crear un cancel token para poder cancelar la petición
  // const source = axios.CancelToken.source();

  // Función para manejar la pérdida de conexión
  // const handleOffline = () => {
  //   console.log('Desconectado de Internet. Cancelando la petición...');
  //   source.cancel('No hay conexión a Internet.');
  //   window.removeEventListener('offline', handleOffline); // Remover el listener para evitar múltiples llamadas
  // };
  // window.addEventListener('offline', handleOffline);

  // /shippings?page=1&page_size=1 // /shippings?page=1&page_size=5
  // /shippings?page=${num}&page_size=${limit}
  try {
    const response = await apiV1AxiosInstance(`/shippings/list/?page=${num}&page_size=${limit}`, {
      method: 'GET',
      timeout: 35000,
    });
    // window.removeEventListener('offline', handleOffline);
    // console.log('-------- getShippingHistory res---------->', response);

    // return response.data;
    return response;
  } catch (error) {
    // window.removeEventListener('offline', handleOffline);
    // if (axios.isCancel(error)) {
    //   console.log('Petición cancelada:', error.message);
    // }
    // console.log('-------- getShippingHistory catch err ---------->', error.response.data);
    // console.log('-------- getShippingHistory catch err ---------->', error); // asi puedo ver este err. Error: timeout of 10000ms exceeded

    return error;
  }
};
const getAddressesDirectory = (string) =>
  apiV1AxiosInstance(`https://api.autopaquete.com.mx/v1/address?name=${string}`, {
    method: 'GET',
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- getAddressesDirectory catch err ---------->', error.response);
      return error;
    });
const getParcelDirectory = (string) =>
  apiV1AxiosInstance(`https://api.autopaquete.com.mx/v1/parcels?name=${string}`, {
    method: 'GET',
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- getParcelDirectory catch err ---------->', error.response.data);
      return error;
    });
const getParcels = (num, limit) =>
  apiV1AxiosInstance(`/parcels?page=${num}&page_size=${limit}`, {
    method: 'GET',
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- getParcel catch err ---------->', error.response);
      return error.response;
    });
const getStatementsHistory = (num, limit) =>
  // /shippings?page=1&page_size=1 // /shippings?page=1&page_size=5
  // ?page=${num}&page_size=${limit}
  apiV1AxiosInstance(`/statements?page=${num}&page_size=${limit}`, {
    method: 'GET',
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- getMovementsHistory catch err ---------->', error.response.data);
      return error;
    });
const getOverweightHistory = (num, limit) =>
  apiV1AxiosInstance(`/overweights/list/?page=${num}&page_size=${limit}`, {
    method: 'GET',
    timeout: 35000,
  })
    .then((response) => {
      // console.log('-------- getOverweightHistory res ---------->', response);
      return response.data;
    })
    .catch((error) => {
      //  console.log('-------- getOverweightHistory catch err ---------->', error.response.data);
      return error.response;
    });
const getDocuments = (docType, page, limit) =>
  // /v1/invoices?page=1&page_size=10
  apiV1AxiosInstance(`/${docType}?page=${page}&page_size=${limit}`, {
    method: 'GET',
    timeout: 35000,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- getDocuments catch err ---------->', error.response.data);
      return error;
    });
const getPickups = (num, limit) =>
  apiV1AxiosInstance(`pickups/list/?page=${num}&page_size=${limit}`, {
    method: 'GET',
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- getPickups catch err ---------->', error.response);
      return error;
    });
const getFilteredShippings = (idLabel) =>
  apiV1AxiosInstance(`/shippings/list/?shipping_id=${idLabel}`, {
    method: 'GET',
  })
    .then((response) => {
      // console.log('-------- getFilteredShippings response ---------->', response.data);
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- getFilteredShippings catch err ---------->', error.response);
      return error.response;
    });

const getShippingDetails = (idLabel) =>
  apiV1AxiosInstance(`/shippings/detail/${idLabel}`, {
    method: 'GET',
  })
    .then((response) => {
      // console.log('-------- getShippingDetails response ---------->', response);
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- getShippingDetails catch err ---------->', error.response);
      return error.response;
    });
const getOrders = (num, limit) =>
  apiV1AxiosInstance(`/orders?page=${num}&page_size=${limit}`, {
    method: 'GET',
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- getOrders catch err ---------->', error.response);
      return error.response;
    });
const getOrderById = (orderId) =>
  apiV1AxiosInstance(`/orders/${orderId}`, {
    method: 'GET',
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- getOrderDetail catch err ---------->', error.response);
      return error.response;
    });
// REVISAR SI ESTOS DE ABAJO SE UTILIZAN
const getAddresses = (currentPage, limit) =>
  // /address?name=&page=1&page_size=5
  // `/address?name=${string}&page=${num}&page_size=${limit}`
  apiV1AxiosInstance(`/address?name=&page=${currentPage}&page_size=${limit}`, {
    method: 'GET',
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- getAddresses catch err ---------->', error.response.data);
      return error;
    });
// * Start Detalle de envio Page id = id del documento *
const getShipment = (idDoc) =>
  apiV1AxiosInstance(`/shippings/${idDoc}`, {
    method: 'GET',
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- getShipment catch err ---------->', error.response);
      return error;
    });
const getSupportTickets = (num, limit) =>
  apiV1AxiosInstance(`/tickets/soporte?page=${num}&page_size=${limit}`, {
    method: 'GET',
  })
    .then((response) => {
      // console.log('-------- getSupportTickets response ---------->', response);
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- getSupportTickets  catch err ---------->', error.response);
      return error.response;
    });
const getResourcesForm = () =>
  apiV1AxiosInstance('/incidences/', {
    method: 'GET',
  })
    .then((response) => {
      // console.log('--------getResourcesForm response ---------->', response);

      return response.data;
    })
    .catch((error) => {
      // console.log('--------getResourcesForm  catch err ---------->', error.response);
      return error.response;
    });
// ****************** POSTS ********************************

const createShipping = (shippingCode, addressFrom, addressTo, parcel) =>
  apiV1AxiosInstance('/shippings', {
    method: 'POST',
    data: {
      shipping_code: shippingCode,
      address_from: addressFrom,
      address_to: addressTo,
      parcel,
    },
    timeout: 35000,
  })
    .then((response) => {
      // console.log('-------- createShipping PRUEBAS---------->', response);
      // return response.data;
      return response;
    })
    .catch((error) => {
      // console.log('-------- createShipping catch err ---------->', error);
      //  error.response.data = {error:[], error_code:""}
      // error.response = { config: , data:{error:[], error_code:""} , headers: , request: ,status: ,statusText: , etc..}
      return error.response;
    });
const createAddress = (newAddress) =>
  apiV1AxiosInstance('/address', {
    method: 'POST',
    data: {
      street_number: newAddress.streetNumber,
      company: newAddress.company, // opcional
      phone: newAddress.phone,
      street: newAddress.street,
      zip_code: newAddress.zip,
      email: newAddress.email, // opcional
      reference: newAddress.placeRef, // opcional
      neighborhood: newAddress.neighborhood,
      name: newAddress.name,
      address_type: newAddress.addressType,
    },
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- createAddress catch err ---------->', error.response.data);
      return error.response;
    });
const createParcel = (parcel) =>
  apiV1AxiosInstance('/parcels', {
    method: 'POST',
    data: {
      name: parcel.name,
      product_description: parcel.description,
      height: parcel.height,
      width: parcel.width,
      depth: parcel.depth,
      weight: parcel.weight,
      product_value: parcel.value ? parcel.value : 0,
      insurance: parcel.insurance,
    },
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- createParcel catch err ---------->', error.response.data);
      return error.response;
    });
const createPickup = (pickup) =>
  apiV1AxiosInstance('/pickups', {
    method: 'POST',
    data: {
      shipping_code: pickup.shipping_code,
      address_from: {
        company: pickup.company,
        name: pickup.name,
        phone: pickup.phone,
        street: pickup.street,
        street_number: pickup.street_number,
        email: pickup.email,
        neighborhood: pickup.neighborhood,
        zip_code: pickup.zip_code,
        reference: pickup.reference,
        state: pickup.state,
        rfc: pickup.rfc,
        city: pickup.city,
      },
      shipping_id: pickup.shipping_id,
      total_packages: pickup.total_packages,
      total_weight: pickup.total_weight,
      pickup_time: pickup.pickup_time,
      pickup_date: pickup.pickup_date,
      side_streets: pickup.side_streets,
    },
  })
    .then((response) => {
      // console.log('-------- createPickup res ---------->', response.data);
      return response.data;
    })
    .catch((error) => {
      console.log('-------- createPickup catch err ---------->', error.response);
      return error.response;
    });
const createOrder = (formData) =>
  apiV1AxiosInstance('/bulk-shippings', {
    method: 'POST',
    data: formData,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- createOrder catch err ---------->', error.response.data);
      return error.response;
    }); /// tampoco se usa ???
const getShippingPdf = (labelId) =>
  apiV1AxiosInstance('/download-label', {
    method: 'POST',
    data: {
      shipping_id: labelId,
    },
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- getShippingPdf catch err ---------->', error.response);
      return error.response;
    });
const getOrderZip = (orderId) =>
  apiV1AxiosInstance('/download-order', {
    method: 'POST',
    data: {
      order_id: orderId,
    },
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- getOrderZip catch err ---------->', error.response);
      return error.response;
    });
const getOrderExcel = (type) =>
  apiV1AxiosInstance('/download-format', {
    method: 'POST',
    data: {
      code_concept: type,
    },
  })
    .then((response) => {
      //  console.log('-------- getOrderExcel res ---------->', response);
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- getOrderExcel catch err ---------->', error.response.data);
      return error.response;
    });
const createSupportTicket = async (data) => {
  // console.log('-------- data  ---------->', data);
  const productDetails = `REPARAR?: ${toUpperIfString(data.isRepairable) ?? 'NO ESPECIFICADO'}| COSTO: ${toUpperIfString(data.repairCost) ?? '0'}| CINTA?: ${
    toUpperIfString(data.packageTape) ?? 'NO ESPECIFICADO'
  }`;
  const productDescription = `DAÑO: ${toUpperIfString(data.packagingInternalDescription) ?? 'NO ESPECIFICADO'}| PZAS: ${
    toUpperIfString(data.missingParts) ?? '0'
  }| COINCIDE?: ${toUpperIfString(data.damagedDescription) ?? 'NO ESPECIFICADO'} `;
  // Create an object FormData
  const formData = new FormData();
  // Add form's fields to FormData
  formData.append('shipping_id', data.shippingId);
  formData.append('incident_type', data.incidentType);
  formData.append('shipping_company', data.serviceName);
  formData.append('insurance_policy', data.isInsurance ? 'Si' : 'No');
  formData.append('to_street', `${toUpperIfString(data.street ?? '')} ${data.streetNumber ?? ''}`);
  formData.append('to_neighborhood', toUpperIfString(data.neighborhood ?? ''));
  formData.append('to_zip_code', data.cp ?? '');
  formData.append('to_city', toUpperIfString(data.city ?? ''));
  formData.append('to_state', toUpperIfString(data.state ?? ''));
  formData.append('to_references', toUpperIfString(data.references ?? ''));
  formData.append('to_phone', data.phoneTo ?? '');
  formData.append('email', data.email ?? '');
  formData.append('to_sidestreets', toUpperIfString(data.betweenStreet ?? ''));
  formData.append('to_facade_color', toUpperIfString(data.facade ?? ''));
  formData.append('from_sidestreets', toUpperIfString(data.betweenStreetFrom ?? ''));
  formData.append('from_references', toUpperIfString(data.referencesFrom ?? ''));
  // info package
  formData.append('package_content', toUpperIfString(data.packageContent ?? ''));
  formData.append('package_value', 1);
  formData.append('packaging_description', toUpperIfString(data.packagingDescription ?? ''));
  formData.append('delivery_type', data.deliveryType ?? '');
  formData.append('missing_product_value', 1);
  formData.append('missing_product_details', productDetails);
  formData.append('missing_product_description', productDescription);
  formData.append('quantity_packages', 1);
  formData.append('total_weight_packages', 1);
  // evidencia
  formData.append('invoice_doc', data.invoiceFile);
  formData.append('evidence_1', data.photograph1 ?? '');
  formData.append('evidence_2', data.photograph2 ?? '');
  formData.append('evidence_3', data.photograph3 ?? '');
  formData.append('evidence_4', data.photograph4 ?? '');
  formData.append('evidence_5', data.photograph5 ?? '');
  formData.append('evidence_6', data.photograph6 ?? '');
  formData.append('evidence_7', data.protectionPhoto ?? '');
  formData.append('evidence_8', data.contentPhoto ?? '');
  formData.append('evidence_9', data.label);
  // formData.append('evidence_10', data.);
  // additional
  formData.append('additional_comments', toUpperIfString(data.comment));
  // console.log('FormData contents:', [...formData.entries()]);
  try {
    const response = await apiV1AxiosInstanceForm.post('/tickets/soporte', formData, {
      timeout: 35000,
    });
    // console.log('-------- createSupportTickets response  ---------->', response);
    return response.data;
  } catch (error) {
    // console.log('-------- creategetSupportTickets  catch err ---------->', error.response);
    return error.response;
  }
};
const createFinaceTicket = async (data) => {
  // Create an object FormData
  const formData = new FormData();
  // Add form's fields to FormData
  formData.append('shipping_id', data.labelId);
  formData.append('package_carrier', data.serviceName);
  formData.append('package_content', data.packageContent);
  formData.append('incident_type', data.incidentType);
  // formData.append('date_request', data.);
  // formData.append('date_create', data.);
  // formData.append('measures', data.);
  // formData.append('weight', data.);
  // formData.append('cost', data.);
  // formData.append('account_info', data.);
  // formData.append('reason_request', data.);
  // formData.append('long_pic', data.);
  // formData.append('width_pic', data.);
  // formData.append('height_pic', data.);
  // formData.append('guide_pic', data.);
  // formData.append('weight_pic', data.);
  // formData.append('', data.);

  // Use Axios Instance to send the data
  try {
    const response = await apiV1AxiosInstanceForm.post('/tickets/finanzas', formData, {
      timeout: 35000,
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

// ****************** DELETE ********************************

const deleteAddress = (id) =>
  apiV1AxiosInstance(`/address/${id}`, {
    method: 'DELETE',
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- deleteAddress catch err ---------->', error.response.data);
      return error;
    });
const deleteParcel = (id) =>
  apiV1AxiosInstance(`/parcels/${id}`, {
    method: 'DELETE',
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- deleteParcels catch err ---------->', error.response.data);
      return error.response;
    });
// ****************** PUT ********************************

const editAddress = (id, address) =>
  apiV1AxiosInstance(`/address/${id}`, {
    method: 'PUT',
    data: {
      street_number: address.streetNumber,
      company: address.company,
      phone: address.phone,
      street: address.street,
      zip_code: address.zip,
      email: address.email,
      reference: address.reference,
      neighborhood: address.neighborhood,
      name: address.name,
      address_type: address.addressType,
    },
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- editAddress catch err ---------->', error.response.data);
      return error.response;
    });
const editParcel = (id, parcel) =>
  apiV1AxiosInstance(`/parcels/${id}`, {
    method: 'PUT',
    data: {
      name: parcel.name,
      product_description: parcel.product_description,
      height: parcel.height,
      width: parcel.width,
      depth: parcel.depth,
      weight: parcel.weight,
      product_value: parcel.product_value,
      insurance: parcel.insurance,
    },
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // console.log('-------- editParcels catch err ---------->', error.response.data);
      return error.response;
    });

export {
  login,
  getCounters, // last 5 records in dashboar
  getZipCode, // Envios y coqtizador
  getRates, // Envios y coqtizador
  getShippingHistory, // Historial pag
  getFilteredShippings, // Detalle pag
  getShippingDetails,
  getShipment,
  getAccountSTP,
  getLastShipping,
  getAddresses,
  getAddressesDirectory,
  getParcelDirectory,
  getStatementsHistory,
  getOverweightHistory,
  getParcels,
  getDocuments,
  getPickups,
  getOrders,
  getOrderById,
  getSupportTickets,
  getResourcesForm,
  // CREATE
  createShipping,
  createAddress,
  createParcel,
  createPickup,
  createOrder,
  getShippingPdf,
  getOrderZip,
  getOrderExcel,
  createSupportTicket,
  createFinaceTicket,
  // DELETE
  deleteAddress,
  deleteParcel,
  // EDIT
  editAddress,
  editParcel,

  // getShippingDetail, // no se usa
};
// const getShippingDetail = (shippingId) =>
//   apiV1AxiosInstance(`/shippings/${shippingId}`, {
//     method: 'GET',
//   })
//     .then((response) => {
//       return response.data;
//     })
//     .catch((error) => {
//       // console.log('-------- getShippingDetail catch err ---------->', error.response.data);
//       return error;
//     });
